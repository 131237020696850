
import $ from 'jquery'

const allCellsStyle = {
    // padding: '10px 100px',
    backgroundColor : '#FFFFFF',
    border : '1px solid'
}

export function Td({children,style,onFocus,onBlur,onMouseDown,onMouseMove,onMouseUp,className,onMouseEnter,onMouseLeave,onClick}){

    style = $.extend({},allCellsStyle,style,{
    });
    
    return <td
        style={style}
        onMouseEnter = {onMouseEnter}
        onMouseLeave = {onMouseLeave}
        onClick = {onClick}
        onFocus = {onFocus}
        onMouseDown = {onMouseDown}
        onMouseMove = {onMouseMove}
        onMouseUp = {onMouseUp}
        onBlur = {onBlur}
        className = {"noSelect "+className}
    >
        {children}
    </td>
}

export function FirstTd({children,style,className,onClick,onContextMenu}){
    style = $.extend({},allCellsStyle,{
        backgroundColor :'#F0F0F0',
    },style,{
        position: '-webkit-sticky', // for safari
        position: 'sticky',
        left: '0px',
        zIndex: 20,
        // top: 0px; -> doesn't work in Safari 11
        /*
          thanks to https://github.com/jonjohnjohnson
          explanation: https://github.com/w3c/csswg-drafts/issues/865 
        */
    });
    
    return <td
        style={style}
        onClick = {onClick}
        onContextMenu = {onContextMenu}
        className = {"noSelect "+className}
    >
        {children}
    </td>
    
}

export function Th({children,style,className,width,onContextMenu,onClick,onMouseDown,onMouseMove,onMouseUp,onDragOver}){

    const styleDefaults = {
        backgroundColor :'#F0F0F0',
    }

    style = $.extend({},allCellsStyle,styleDefaults,style,{
        position: '-webkit-sticky', // for safari
        position: 'sticky',
        top: '0', 
        left: '0',
        width:width,
        minWidth:width,
        maxWidth:width,

    });
    
    return <th
        style={style}
        className = {"noSelect "+className}
        onClick={onClick} 
        onContextMenu={onContextMenu}
        onMouseDown = {onMouseDown}
        onMouseMove = {onMouseMove}
        onMouseUp = {onMouseUp}
        onDragOver = {onDragOver}
    >
        {children}
    </th>

}

export function FirstTh({children,style,className,width}){
    style = $.extend({},style,allCellsStyle,{
        
        border : '1px solid',

        position: '-webkit-sticky', // for safari
        position: 'sticky',
        backgroundColor :'#F0F0F0',
        top: '0', 
        left: '0',

        zIndex: 30,
        
        width:width,
        minWidth:width,
        maxWidth:width,

    });
    
    return <th
        style={style}
        className = {"noSelect "+className}
    >
        {children}
    </th>
    
}

export function Table({children,style,className}){

    style = $.extend({},style,{
        overflowX: 'auto',
        overflowY: 'auto',
        border : '1px solid'
    });

    return <div
        style={style}
        className = {"noSelect "+className}
    >
        <table
            style={{
                border : 'none',
                borderCollapse: 'collapse',
                tableLayout: 'fixed',
            }}
            className = "noSelect "
        >
            {children}
        </table>
    </div>


}

export default {
    Table,
    Cell:Td,
    RowHeader : FirstTd,
    ColHeader:Th,
    Corner : FirstTh
}

