
import { useSelector } from "react-redux";
import React, { useEffect ,useRef,useState} from "react"
import { sendCommand } from "../Command"
import { Button, Stack ,Container,Row } from "react-bootstrap";
import { isUndef, useReadableState,ToggleButton } from "../general";
import { getAvailableTags } from "./notes";
import { isDef } from "../general";
import { CheckBox } from "../general";
import $ from 'jquery';
import { showDialog } from "../ModalDialog";

export function doTagsPassFilter(tags,filter){
 
    var ors = null;
    var ands = null;
    var nots = null;

    for (var tagKey in filter){
        
        var f = filter[tagKey];
        if (f) {
            if (f.or){
                ors=ors?ors:{};
                ors[tagKey]=true;
            }
            if (f.and){
                ands=ands?ands:{};
                ands[tagKey]=true;
            }
            if (f.not){
                nots=nots?nots:{};
                nots[tagKey]=true;
            }
        }
    }

    var passedOr = true;
    if (ors){
        passedOr = false;
        for (var tagKey in ors){
            if (tags[tagKey]){
                passedOr = true; 
                break;
            }
        } 
    }
    if (!passedOr){
        return false;
    }
    
    if (ands){
        for (var tagKey in ands){
            if (!tags[tagKey]){
                return false;
            }
        }
    }

    if (nots){
        for (var tagKey in nots){
            if (tags[tagKey]){
                return false;
            }
        }
    }
    return true;                

}

export function TagSelector({tags,availableTags,onChanged}){
    
    const [getSelected,setSelected] = useReadableState(tags?tags:{});

    var nextKeyId = 1;
    const existingPoolTagButtons = [];

    function onTagClicked(tagName,value){
        var f = $.extend({},getSelected());
        if (value){            
            f[tagName.toLowerCase()]=tagName;
        } else {
            delete f[tagName.toLowerCase()];
        }
        setSelected(f);
        onChanged(f);   
    }

    const selected = getSelected();
    for(var tagKey in availableTags){
        (function(tagKey){
            var tagName = availableTags[tagKey];
            existingPoolTagButtons.push (
                React.createElement(ToggleButton,{
                    className:"m-1 p-1",
                    size : 'sm',
                    key : nextKeyId++,
                    text : tagName,
                    value : !!selected[tagKey],
                    onChanged : (e)=>{onTagClicked(tagName,e.target.value)}
                })
            );
        })(tagKey)
    }

    return <div 
        className= {"m-0 p-0 "} 
        style = {{
            display: 'flex',
            justifyContent: 'right',
            flexWrap: 'wrap',
        }}
    >
        {existingPoolTagButtons}
    </div>
}

function TagFilter({tagName,onChanged,keyId,className,style}){

    const [ getOr , setOr ] = useReadableState(false);
    const [ getAnd , setAnd ] =useReadableState(false);
    const [ getNot , setNot ] =useReadableState(false);

    function sendChange(){
        if(onChanged){
            onChanged({target:{value:{or:getOr(),and:getAnd(),not:getNot()}}});
        }
    }

    function onOrChanged(e){
        setOr(e.target.value);
        sendChange();
    }

    function onAndChanged(e){
        setAnd(e.target.value);
        sendChange();
    }

    function onNotChanged(e){
        setNot(e.target.value);
        sendChange();
    }

    return (<div key={keyId} className={className} style={style}>
        <Stack direction="horizontal">
            <div style={{width:'10em'}}><span>{tagName}</span></div>
            <CheckBox className="me-2" value={getOr()} onChanged={onOrChanged} key={1} keyId={1}/>
            <CheckBox className="mx-2" value={getAnd()} onChanged={onAndChanged} key={2} keyId={2}/>
            <CheckBox className="ms-2" value={getNot()} onChanged={onNotChanged} key={3} keyId={3}/>
        </Stack>
    </div>)

}

function TagLine({parentNoteId,tagName,onChanged,keyId,className,style}){

    const [ getSelected , setSelected ] = useReadableState(false);
    const [isOver, setIsOver] = useState(false);
    
    function onToggled(e){
        setSelected(e.target.value);
        if(onChanged){
            const selected = getSelected();
            onChanged({target:{value:{or:false,and:selected,not:false}}});
        }
    }

    function onDelete(){
        showDialog({
            title : "Delete Tag : '"+tagName+"'",
            message : ['Are you sure you want to delete this tag?','This action can not be undone.'],
            cancel : true,
            ok : 'Delete',
            onOk : ()=>{
                sendCommand([
                    {
                        objectType : "Pool",
                        verb : "deleteTag",
                        poolId : parentNoteId,
                        tagName : tagName
                    },{
                        objectType : "Note",
                        verb : "fetch",
                        noteId : parentNoteId
                    }]
                );
            }

        })

    }

    return (<div key={keyId} className={className} style={style}>
        <Stack 
            direction="horizontal"
            onMouseEnter={() => setIsOver(true)}
            onMouseLeave={() => setIsOver(false)}
        >
            <ToggleButton  
                onChanged = {onToggled}
                text = {tagName}
            />
            <Button
                className= { (isOver?"":" d-none ") + " ms-auto py-1 px-2 " }
                size="sm"
                variant="outline-dark"
                onClick = {onDelete}
            >X</Button>
        </Stack>
    </div>)

}

export function PoolFilter({className,style,parentNoteId,onFilterChanged}){

    const filter = useRef({});
    const [getNetTagName,setNewTagName,onTagNameChanged] = useReadableState('');

    const availableTags = useSelector( state => {
        return getAvailableTags(state,parentNoteId);
    } );

    function tagFilterChanged(tagName,newFilter){
        filter.current = $.extend({},filter.current)
        filter.current[tagName.toLowerCase()]=newFilter;
        if (onFilterChanged){
            onFilterChanged(filter.current);
        }
    }
    var nextKeyId = 1;

    const tagFilters = [];
    for (var tagKey in availableTags){
        (function(tagKey){
            var tagName = availableTags[tagKey];
            tagFilters.push(
                // <TagFilter className=" my-2 " tagName={tagName} onChanged={e=>tagFilterChanged(tagKey,e.target.value)  } key={nextKeyId++} keyId={nextKeyId++}/>
                <TagLine className=" my-2 " parentNoteId={parentNoteId} tagName={tagName} onChanged={e=>tagFilterChanged(tagKey,e.target.value)  } key={tagName} keyId={tagName}/>
            );
        })(tagKey)
    }
    
    function onKeyDown(event) {
        if (event.keyCode === 13) {
            
            sendCommand([
                {
                    objectType : "Pool",
                    verb : "addTag",
                    poolId : parentNoteId,
                    tagName : getNetTagName()
                },{
                    objectType : "Note",
                    verb : "fetch",
                    noteId : parentNoteId
                }]
            );
            
            setNewTagName('');
        }
    }

    style = $.extend({},style,{});
    
    return <Stack
        direction='vertical'
        style = {style}
        className = {className + " "+ "p-2"}
        id = "poolFilter"
    >
        {tagFilters}
        <input
            onKeyDown = {onKeyDown}
            className = "my-2 w-100"
            type="text"
            value={getNetTagName()}
            onChange={onTagNameChanged}
            placeholder="New tag..."
        />
    </Stack>

}
