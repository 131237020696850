// Import React dependencies.
import React, { useCallback, useState } from 'react'
// Import the Slate editor factory.
import { createEditor, Editor, Element, Node, Path, Transforms } from 'slate'
// Import the Slate components and React plugin.
import { Slate, Editable, withReact, useSlate } from 'slate-react'
import { isString } from '../general';
import $ from 'jquery';
import { withHistory } from 'slate-history';

const initialValue = [
    {
        type: 'paragraph',
        children: [{ text: 'A line of text in a paragraph.' }],
    },{
        type: 'paragraph',
        children: [{ text: 'A line of text in a paragraph.' }],
    },{
        type: 'paragraph',
        children: [{ text: 'A line of text in a paragraph.' }],
    },{
        type: 'paragraph',
        children: [{ text: 'A line of text in a paragraph.' }],
    },{
        type: 'paragraph',
        children: [{ text: 'A line of text in a paragraph.' }],
    }
];

export function SlateApp(){
    
    function onChange(e){
        console.log(JSON.stringify(e));
    }
    return <SlateEditor
        value = {initialValue}
        onChange = {onChange}
    />
}

function renderLeaf (props) {
    return <Leaf {...props} />
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const toggleMark = (editor, format) => {
    toggleMarkValue(editor, format,true);
}

const toggleMarkValue = (editor, format,value) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, value)
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.red) {
        children = <span style={{color:'red'}}>{children}</span>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

function renderElement (props) {

    const style = {};
    
    // props.attributes.style = props.attributes.style?props.attributes.style:{};

    if (props.element.bullet){
        style.listStyleType = '"'+props.element.bullet+' "';
        style.paddingLeft='1em'
        style.margin='0px'
    }

    var indent = props.element.indent;
    if (indent){
        style.marginLeft = (indent*2)+'em';
    }

    if (props.element.isBold){
        style.fontSize = '1.2em';
        style.fontWeight = 'bold';
    }

    // if (props.element.color){
    //     style.color = props.element.color;
    // }

    if (props.element.backColor){
        style.backgroundColor = props.element.backColor;
    }

    if (props.element.bullet){
        return <ul style={style}  {...props.attributes} ><li  >{props.children}</li></ul>
    } else {
        return <div  style={style} {...props.attributes}>{props.children}</div>
    }
}

export const SlateEditor = (props) => {

    const slateProps = {
        value:props.value,
        onChange : props.onChange,
    }

    if (!slateProps.value){
        slateProps.value = [
            {
                type: 'paragraph',
                children: [{ text: '' }],
            },
        ]
    } else if (isString(slateProps.value)) {
        slateProps.value = JSON.parse(slateProps.value)
    }

    const [editor] = useState(() => withHistory(withReact(createEditor())));

    
    
    function getAnchorElement(){
        var path = editor.selection.anchor.path;
        var node = Node.get(editor,path);
        while (node.type!=='paragraph'){
            path = Path.parent(path);
            node = Node.get(editor,path);
        } 
        return [node,path];
    }
    function matchElement(node){
        const result = !!node.type
        return result;
    }
    return (
        <div {...props} >
            <Slate editor={editor} {...slateProps} >
                <Editable 
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={event => {

                        if (event.key === 'r' && event.ctrlKey && event.metaKey) {
                            event.preventDefault();
                            Transforms.setNodes(
                                editor,
                                { backColor: '#FFD0D0' } , { match : matchElement }
                            )
                        } else if (event.key === 'g' && event.ctrlKey && event.metaKey) {
                            event.preventDefault()
                            Transforms.setNodes(
                                editor,
                                { backColor: '#D0FFD0' } , { match : matchElement }
                            )
                        } else if (event.key === 'b' && event.ctrlKey && event.metaKey) {
                            event.preventDefault()
                            Transforms.setNodes(
                                editor,
                                { backColor: '#D0D0FF' } , { match : matchElement }
                            )
                        } else if (event.key === 'p' && event.ctrlKey && event.metaKey) {
                            event.preventDefault()
                            Transforms.setNodes(
                                editor,
                                { backColor: '#FFD0FF' } , { match : matchElement }
                            )
                        } else if (event.key === 'y' && event.ctrlKey && event.metaKey) {
                            event.preventDefault()
                            Transforms.setNodes(
                                editor,
                                { backColor: '#FFFFD0' } , { match : matchElement }
                            )
                        } else if (event.key === 'w' && event.ctrlKey && event.metaKey) {
                            event.preventDefault();
                            Transforms.setNodes(
                                editor,
                                { backColor: null } , { match : matchElement }
                            )
                        } else if (event.key === '' && event.metaKey && event.metaKey) {

                            event.preventDefault()

                            const [anchorElement,path] = getAnchorElement();

                            const isBold = !!anchorElement.isBold;

                            Transforms.setNodes(
                                editor,
                                { isBold: !isBold } , {
                                    at:path
                                }
                            )
                        } else if (event.key === 'r' && event.ctrlKey) {

                            toggleMark(editor,'red');

                            // event.preventDefault()

                            // const [anchorElement,path] = getAnchorElement();

                            // const isRed = anchorElement.color == 'red';

                            // Transforms.setNodes(
                            //     editor,
                            //     { color: isRed?false:'red' } , {
                            //         at:path
                            //     }
                            // )

                        } else if (event.key === 'g' && event.ctrlKey) {

                            event.preventDefault()

                            const [anchorElement,path] = getAnchorElement();

                            const isGreen = anchorElement.color == 'green';

                            Transforms.setNodes(
                                editor,
                                { color: isGreen?false:'green' } , {
                                    at:path
                                }
                            )

                        } else if (event.key === ']' && event.metaKey) {
                            event.preventDefault()

                            const [anchorElement,path] = getAnchorElement();

                            const indent = anchorElement.indent?anchorElement.indent:0;

                            Transforms.setNodes(
                                editor,
                                { indent: indent+1 } , {
                                    // at:path,
                                    match : matchElement
                                }
                            )

                        } else if (event.key === '[' && event.metaKey) {
                            event.preventDefault()

                            const [anchorElement,path] = getAnchorElement();

                            const indent = anchorElement.indent?anchorElement.indent:0;

                            if (indent){
                                Transforms.setNodes(
                                    editor,
                                    { indent: indent-1 } , {
                                        // at:path,
                                        match : matchElement
                                    }
                                )
                            }

                        }  else if (event.key === '/' && event.metaKey) {

                            event.preventDefault()

                            const [anchorElement,path] = getAnchorElement();

                            const isBulleted = !!anchorElement.bullet;

                            Transforms.setNodes(
                                editor,
                                { bullet: isBulleted?false:'?'} , {
                                    // at:path,
                                    match : matchElement
                                }
                            )
                        } else if (event.key === '-' && event.metaKey) {

                            event.preventDefault()

                            const [anchorElement,path] = getAnchorElement();

                            const isBulleted = !!anchorElement.bullet;

                            Transforms.setNodes(
                                editor,
                                { bullet: isBulleted?false:'-'} , {
                                    // at:path,
                                    match : matchElement
                                }
                            )
                        } else if (event.key === 'b' && event.ctrlKey) {
                            toggleMark(editor,'bold');
                        } else if (event.key === 'i' && event.ctrlKey) {
                            toggleMark(editor,'italic');
                        } else if (event.key === 'u' && event.ctrlKey) {
                            toggleMark(editor,'underline');
                        }
                    }}
                
                />
            </Slate>
        </div>
    )
}