import { useState,useRef,useEffect,useContext } from "react";
import React from "react";
import $, { extend} from 'jquery'
import { Button , Container,Row} from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import {Stack , DropdownButton } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";

import { filterStack, isDef, objLength, useEvent, useMount, useReadableState, useResettableProp } from "../general";
import { sendCommand } from "../Command";
import { setLeavePageEventHandler } from "../..";
import { ToggleButton } from "../general";
import { updateStoreModelObject } from "../tagnoteStore";
import { browserContext, pageContext } from "../pageContext";
import { showDialog } from "../ModalDialog";
import { getNoteContentComponent } from "./notes";
import { noteComparer } from "./notes";
import { makeEmptyNoteContent, Page, removePoolFromModel } from "./page";
import { useSelector } from "react-redux";
import StickyT from '../FrozenTable';
import { NewNoteButton } from "./notes";
import { hideContextMenu, showContextMenu } from "../ContextMenu";
import {  doTagsPassFilter, TagSelector } from "./tags";

export function ContentGrid({noteId,style,className}){

    // console.log("Rendering ContentGrid : ");

    useMount(()=>{
        // console.log("Mounting ContentGrid : " );
    })

    return <GridPage
        poolId = {noteId}
        style = {style}
        className = {className}
        id = "GridPage"
    />

}

export function Blank(){
    console.log ("Rendering Blank")

    useMount(()=>{
        console.log ("Initializing Blank");
    });

    const init = useRef(false);


    // useEffect(()=>{
    //     console.log ("Effecting Blank :" + init.current)
    //     init.current = true;
    // })
    return <div></div>
}
export function GridPage({poolId,style,className}){

    style=$.extend({},style,{
        // backgroundColor:'red'
    });
    
    
    const tools = [
        {
            name:'CT',
            key:'colTags',
            type:'checkbox',
        }
    ];

    return <Page
        style = {style}
        className = {className}
        Inner = {Grid}
        poolId = {poolId}
        // externalHeight = {true}
        tools = {tools}
    />

}


// function ColHeaderCellContent({column,onCommand}){

//     const [mouseOver,setMouseOver] = useState(false);
//     const [dropdownOpen,setDropdownOpen] = useState(false);
    
//     const [getTitleTimer,setTitleTimer] = useReadableState(null);

//     function clearTitleTimer(){
//         const titleTimer = getTitleTimer();
//         if (titleTimer){
//             clearTimeout(titleTimer);
//             setTitleTimer(null);
//         }
//     }

//     function startTitleTimer(){
//         clearTitleTimer();
//         const titleTimer = setTimeout(() => {
//             setTitleTimer(null);
//             onCommand('renameCol',{col:column});
//         }, 500);
//         setTitleTimer(titleTimer);
//     }

//     return <Stack
//         direction='horizontal'
//         style={{
//             // backgroundColor : 'red',
//             width : '100%',
//             //position : 'relative',
//             //zIndex : 25 // under the corner but over the cells
//         }}
//         onMouseEnter={()=>setMouseOver(true)}
//         onMouseLeave={()=>setMouseOver(false)}
//     >
//         <div
//             className="w-100"
//             onMouseDown = {startTitleTimer}
//             onMouseUp = {clearTitleTimer}
//             onMouseLeave= {clearTitleTimer}
//         >
//             {/* {column.name} */}
//         </div>

//         <div
//             style ={{
//                 height:'100%',
//                 width : '1em',
//                 backgroundColor:'red'
//             }}
//         >.</div>
//     </Stack>
// }

function makeCellKey(colId,rowId){
    return colId + ":" + rowId;
}

function GridCell({noteId,style,className,getRef}){

    const note = useSelector(state => {
        var note = null;
        if (state && state.model && state.model.notes){
            note = state.model.notes[noteId]
        }
        return  note;
    },noteComparer);

    const ContentComponentType = note?getNoteContentComponent(note.type):<div></div>;

    var content = null;
    var button = null;

    if (note){
        const ContentComponentType = getNoteContentComponent(note.type);
        content = <ContentComponentType
            noteId = {noteId}
            value = {note.content}
            style = {style}
            className = {className}
            getRef = {getRef}
        />

    }

    return <div
        style={{
            position:'relative',
        }}
    >
        {content}
        {/* {button} */}
    </div>;
}

export function GridNoteTableCell ({onNewNote,col,row,cellNoteId,poolId}){

    const [over,setOver] = useState(false);
    const focusable = useRef(null);
    // const [focused,setFocused] = useState();

    const [getDeleteTimer,setDeleteTimer] = useReadableState(null);

    function clearDeleteTimer(){
        const deleteTimer = getDeleteTimer();
        if (deleteTimer){
            clearTimeout(deleteTimer);
            setDeleteTimer(null);
        }
    }

    function startDeleteTimer(){
        clearDeleteTimer();
        const deleteTimer = setTimeout(() => {
            setDeleteTimer(null);
            executeGridCommand('deleteNote',{noteId:cellNoteId},poolId);
        }, 500);
        setDeleteTimer(deleteTimer);
    }

    var cellKey = makeCellKey(col.id,row.id);
    var cellContent = null;
    var button = null;
    const cellStyle = {};

    if (isDef(cellNoteId)){
        cellContent = <GridCell 
            key={cellNoteId}
            noteId = {cellNoteId}
            style ={{
                width:'100%',
                height:'100%',
                // backgroundColor : 'red',
                
                
            }}
            getRef = {r=>{focusable.current = r;}}
        />

        // button = <Dropdown
        //     className = {((over)?"":"d-none")}
        //     style ={{
        //         position : 'absolute',
        //         top : '5px',
        //         right : '5px',
        //     }}
        // >
        //     <Dropdown.Toggle 
        //         className={" block p-1 px-2"} 
        //         size="sm"
        //     />
        //     <Dropdown.Menu>
        //         <Dropdown.Item  >Delete</Dropdown.Item>
        //         <Dropdown.Item  >Tags</Dropdown.Item>
        //     </Dropdown.Menu>
        // </Dropdown>

    } else {
        cellContent = <NewNoteButton
            style ={{
                visibility : (over?'visible':'hidden')                    
            }}
            lassName = 'mx-auto '
            onSelect = {onNewNote}
        />
    }

    function onClick(){
        if (focusable.current){
            focusable.current.focus()
        }
    }

    return <StickyT.Cell
        key = {col.id}
        onMouseEnter = {()=>{setOver(true);}}
        onMouseLeave = {()=>{setOver(false);clearDeleteTimer()}}

        onMouseDown = {startDeleteTimer}
        onMouseUp = {clearDeleteTimer}
        onMouseMove = {clearDeleteTimer}

        // onFocus = {()=>setFocused(true)}
        // onBlur = {()=>setFocused(false)}
        onClick = {onClick}
        style={{
            textAlign: 'center',
            verticalAlign: button?'top':'middle',
            position:'relative',  // to allow context menu button to positioned absolutely
            // backgroundColor : 'yellow'
            // zIndex:1, puts one cell in front of another and stops menus from appearing in front of neighboring cells to the right
        }}

    >
        {cellContent}
        {/* {button} */}
    </StickyT.Cell>

}

function executeGridCommand (cmd,args,poolId,filter){


    function InsertRowsDlgMessage ({ onChanged }){
        const [getValue,setValue,changeValue] = useReadableState("1",onChanged);
        return <input 
            type='text'
            value = {getValue()}
            onChange = {changeValue}
        />
    }

    switch (cmd){
        case 'deleteNote':{
            var noteId = args.noteId;
            
            showDialog({
                title :'Delete this note',
                message : 'Are you sure? This action can not be undone.',
                cancel : true,
                ok : 'Delete note',
                onOk : ()=>{
                    sendCommand({
                        actions:[{
                            objectType:'Note',
                            verb : 'delete',
                            noteId : noteId,
                        },{
                            objectType:'Pool',
                            verb : 'fetch',
                            parentNoteId : poolId
                        }],
                        clearModel : function(model){
                            removePoolFromModel(model,poolId);
                        }
                    })
                }
            })
        }break;
        case 'updateColTags':{  
            var col = args.col;
            var tags = args.tags;
            sendCommand([{
                objectType:'Grid',
                verb : 'updateColTags',
                noteId : poolId,
                colId : col.id,
                tags : JSON.stringify(tags),
            },{
                objectType:'Note',
                verb : 'fetch',
                noteId : poolId
            }])

            }break;
        case 'resizeRowHeader':{
            
            const newWidth = args.newWidth;
            sendCommand([{
                objectType:'Grid',
                verb : 'resizeRow',
                noteId : poolId,
                width : newWidth
            },{
                objectType:'Note',
                verb : 'fetch',
                noteId : poolId
            }])
        
        }break;
        case 'deleteRows' : {
            showDialog({
                title :'Delete rows',
                message : 'Are you sure? This action can not be undone.',
                cancel : true,
                ok : 'Delete Row(s)',
                onOk : ()=>{
                    const rowIds = [];
                    for (var rowId in args.rowIds){
                        rowIds.push(rowId);
                    }
                    sendCommand([{
                        objectType:'Grid',
                        verb : 'deleteRows',
                        noteId : poolId,
                        rowIds : JSON.stringify(rowIds)
                    },{
                        objectType:'Note',
                        verb : 'fetch',
                        noteId : poolId
                    }])
                }
            })
        }break;
        case 'renameRow':{
            
            const row = args.row;
            function ResizeDlgMessage ({ row ,onChanged }){
                const [getValue,setValue,changeValue] = useReadableState(row.name,onChanged);
                return <input 
                    type='text'
                    value = {getValue()}
                    onChange = {changeValue}
                />
            }

            var newName = row.name;

            showDialog({
                title :'Rename Row',
                message : {
                    tag : ResizeDlgMessage,
                    props : {
                        row : row,
                        onChanged : name=>{newName = name}
                    }
                },
                cancel : true,
                ok : true,
                onOk : ()=>{
                    sendCommand([{
                        objectType:'Grid',
                        verb : 'renameRow',
                        noteId : poolId,
                        rowId : row.id,
                        name : newName
                    },{
                        objectType:'Note',
                        verb : 'fetch',
                        noteId : poolId
                    }])
                }
            })
        
        }break;
        case 'moveRows' : {
            const rowIds = [];
            for (var rowId in args.rowIds){
                rowIds.push(rowId);
            }
            const delta = args.delta;
            sendCommand([{
                objectType:'Grid',
                verb : 'moveRows',
                noteId : poolId,
                rowIds : JSON.stringify(rowIds),
                delta : delta
            },{
                objectType:'Note',
                verb : 'fetch',
                noteId : poolId
            }])
        }break;
        case 'insertRows' : {
            
            const near = args.rowId;
            const above = args.above;
            var number = 1;
            showDialog({
                title :'Insert rows '+(above?'above':'below'),
                message : {
                    tag : InsertRowsDlgMessage,
                    props : {
                        onChanged : n=>{number = n}
                    }
                },
                cancel : true,
                ok : 'Insert',
                onOk : ()=>{
                    sendCommand([{
                        objectType:'Grid',
                        verb : 'insertRows',
                        noteId : poolId,
                        above : above,
                        near : near,
                        number : number
                    },{
                        objectType:'Note',
                        verb : 'fetch',
                        noteId : poolId
                    }])
                }
            })
        }break;
        case 'updateGridContent' : {
            const content = args.content;
            sendCommand([{
                objectType:'Note',
                verb : 'update',
                noteId : poolId,
                content : JSON.stringify(content)
            },{
                objectType:'Note',
                verb : 'fetch',
                noteId : poolId
            }])
        }break;
        case 'renameCol':{
            
            const col = args.col;
            function ResizeDlgMessage ({ col ,onChanged }){
                const [getValue,setValue,changeValue] = useReadableState(col.name,onChanged);
                return <input 
                    type='text'
                    value = {getValue()}
                    onChange = {changeValue}
                />
            }

            var newName = col.name;

            showDialog({
                title :'Rename Column',
                message : {
                    tag : ResizeDlgMessage,
                    props : {
                        col : col,
                        onChanged : name=>{newName = name}
                    }
                },
                cancel : true,
                ok : true,
                onOk : ()=>{
                    sendCommand([{
                        objectType:'Grid',
                        verb : 'renameCol',
                        noteId : poolId,
                        colId : col.id,
                        name : newName
                    },{
                        objectType:'Note',
                        verb : 'fetch',
                        noteId : poolId
                    }])
                }
            })
        
        }break;
        case ('deleteCols') : {

            showDialog({
                title :'Delete columns.',
                message : 'Are you sure? This action can not be undone.',
                cancel : true,
                ok : 'Delete Column(s)',
                onOk : ()=>{
                    const colIds = [];
                    for (var colId in args.colIds){
                        colIds.push(colId);
                    }

                    sendCommand([{
                        objectType:'Grid',
                        verb : 'deleteCols',
                        noteId : poolId,
                        colIds : JSON.stringify(colIds)
                    },{
                        objectType:'Note',
                        verb : 'fetch',
                        noteId : poolId
                    }])
                }
            })

            
        }break;
        case 'resizeCol':{
            
            const colId = args.colId;
            const newWidth = args.newWidth;
            
            sendCommand([{
                objectType:'Grid',
                verb : 'resizeColumn',
                noteId : poolId,
                colId : colId,
                width : newWidth
            },{
                objectType:'Note',
                verb : 'fetch',
                noteId : poolId
            }])
        
        }break;
        case ('moveColLeft') : {
            const col = args.col;
            sendCommand([{
                objectType:'Grid',
                verb : 'moveColLeft',
                noteId : poolId,
                colId : col.id,
            },{
                objectType:'Note',
                verb : 'fetch',
                noteId : poolId
            }])
        }break;
        case ('moveColRight') : {
            const col = args.col;
            sendCommand([{
                objectType:'Grid',
                verb : 'moveColRight',
                noteId : poolId,
                colId : col.id,
            },{
                objectType:'Note',
                verb : 'fetch',
                noteId : poolId
            }])
        }break;
        case ('insertColLeft') : {
            const colId = args.colId;
            sendCommand([{
                objectType:'Grid',
                verb : 'insertColLeft',
                noteId : poolId,
                colId : colId,
            },{
                objectType:'Note',
                verb : 'fetch',
                noteId : poolId
            }])
        }break;
        case ('insertColRight') : {
            const colId = args.colId;
            sendCommand([{
                objectType:'Grid',
                verb : 'insertColRight',
                noteId : poolId,
                colId : colId,
            },{
                objectType:'Note',
                verb : 'fetch',
                noteId : poolId
            }])
        }break;
    }
}

export function ColHeader ({col,style,selected,onClick,onContextMenu,onStartColWidthDrag}){

    const dragHandleEle = useRef();
    const hostEle = useRef();
    const [cursor,setCursor] = useState();

    style = $.extend({},style,{
        backgroundColor : selected?'#8080F0':'#F0F0F0',
        overflowX:'hidden', // stops content from stretching the flex box
    });

    function onDragStart(e){
        
        const rectHeader = hostEle.current.getBoundingClientRect() 
        const rectHandle = dragHandleEle.current.getBoundingClientRect() 

        onStartColWidthDrag(e,{col,colLeft:rectHeader.x,oem:rectHandle.width});

    }

    function onMouseMove(e){

    }

    return <StickyT.ColHeader 
        
        key='rowHead'
        onClick = {(e)=>{
            onClick(e.metaKey,e.shiftKey,col.id);
        }}
        width = {col.width+"em"}
        style = {style}
        onContextMenu={(e) => {
            e.preventDefault(); // prevent the default behavior when right clicked
            onContextMenu(e,col.id);
        }}
        // onDragOver = {onDragOver}
    >   
        <div
            style={{
                display:'flex',
                width : col.width+'em',
                maxWidth : col.width+'em',
                minWidth : col.width+'em',
                overflowX :'hidden',
                //cursor : {cursor}
            }}
            onMouseMove = {onMouseMove}
            ref = {e=>hostEle.current=e}
        >
            <div
                style={{
                    width:'100%',
                    overflowX:'hidden', // stops content from stretching the flex box
                    maxWidth : '100%',
                    flexGrow: 2,
                }}
            >
                {col.name}
            </div>
            <div
                ref = {e=>dragHandleEle.current=e}
                style={{
                    height :'100%',
                        flexGrow: 0,
                        flexBasis:'1em',
                    width:'1em',
                    cursor:'col-resize'
                }}
                onMouseDown={onDragStart}
            >
                &nbsp;
            </div>
        </div>
    </StickyT.ColHeader>

}

export function RowHeader ({poolId,row,style,selected,onClick,onContextMenu}){

    // const [getIsSelected,setIsSelected] = useReadableState(false);
    // const [getIsContextMenu,setIsContextMenu] = useReadableState(false);

    style = $.extend({},style,{
        backgroundColor : selected?'#8080F0':'#F0F0F0',
    });

    return <StickyT.RowHeader 
        key='rowHead'
        onClick = {(e)=>{
            onClick(e.metaKey,e.shiftKey,row.id);
        }}
        style = {style}
        onContextMenu={(e) => {
            e.preventDefault(); // prevent the default behavior when right clicked

            onContextMenu(e,row.id);
            
          }}
    >
        {row.name}
    </StickyT.RowHeader>

}

export function Grid({toolEvent,poolId,filter,className,style}){
    
    const [getGridId,setGridId] = useReadableState(poolId);
    const [getSelectedRows,setSelectedRows] = useReadableState({});
    const [getSelectedCols,setSelectedCols] = useReadableState({});
    const [getTagsVisible,setTagsVisible] = useReadableState(false);
    const element = useRef();
    const dragHandleEle = useRef();
    const cornerEle = useRef();
    

    const [getColDragBarX,setColDragBarX] = useReadableState(0);
    const [getColDragInprogress,setColDragInprogress] = useReadableState(false);
    
    useEvent(toolEvent,onToolEvent);

    const bc = useContext(browserContext);
    const {gotoPool,newNote} = bc?bc:{gotoPool:null,newNote:null};

    const {gridNote,cellDefs} = useSelector(state => {
        var gridNote = null;
        var cellDefs = {};
        if (state && state.model && state.model.notes){
            const parentNoteId = getGridId(); 
            gridNote = state.model.notes[parentNoteId];
            const childNotes = filterStack(state.model.notes,"parent",parentNoteId);
            
            childNotes.map((note)=>{
                const content = note.content
                cellDefs[makeCellKey(content.colId,content.rowId)] = note.id;
            });
        }
        return  {
            gridNote: gridNote,
            cellDefs : cellDefs
        }
    },(a,b)=>{
        if (!noteComparer(a.gridNote,b.gridNote)){
            return false;
        } else {
            return JSON.stringify(a.cellDefs) === JSON.stringify(b.cellDefs);
        }
    })

    var table = null;

    var headerRow = null;
    var rows = null;
    var rowHeaderWidth = 0;

    function onCommand(cmd,args,clear){
        executeGridCommand(cmd,args,poolId,filter)
        if (clear){
            hideContextMenu();
            setSelectedRows({})
        }
    }
    function onRowHeaderClicked(controlKey,shiftKey,rowId){
        setSelectedCols({});
        var selectedRows = $.extend({},getSelectedRows());
        if (selectedRows[rowId]){
            if (controlKey){
                delete selectedRows[rowId];
            } else {
                selectedRows = {}
            }
            setSelectedRows(selectedRows);
        } else {
            selectRow(controlKey,shiftKey,rowId)
        }
    }
    function getRowPosition(rowId){

        const rows = gridNote.content.rows;
        for (var i = 0; i < rows.length; i++) { 
            if (rows[i].id==rowId){
                return i;
            }
        }
        return - 1;
    }
    function getRow(rowId){

        const rows = gridNote.content.rows;
        for (var i = 0; i < rows.length; i++) { 
            if (rows[i].id==rowId){
                return rows[i];
            }
        }
        return null;
    }
    function selectRow(controlKey,shiftKey,rowId){
        setSelectedCols({});

        var selectedRows = $.extend({},getSelectedRows());
        if (!controlKey && !shiftKey){
            selectedRows={}
        } 
        
            if (shiftKey && objLength(getSelectedRows()) ){
                // get the position of the clicked row
                const clickedIndex = getRowPosition(rowId);

                // get the top selected row
                const{lowestIndex,highestIndex} = makeSelectedRows();

                if (clickedIndex > highestIndex) {
                    for (var r=highestIndex;r<=clickedIndex;r++){ 
                        selectedRows[ gridNote.content.rows[r].id  ]=true;
                    }
                } else if (clickedIndex < lowestIndex) {
                    for (var r=clickedIndex;r<=lowestIndex;r++){ 
                        selectedRows[gridNote.content.rows[r].id ]=true;
                    }
                }
            } else {
                selectedRows[rowId]=!selectedRows[rowId];
            }
        
        setSelectedRows(selectedRows);
    }
    function makeSelectedRows(){
        var highestIndex = -1;
        var lowestIndex = 100000;
        const selected = [];
        for (var rowId in getSelectedRows()) { 
            var position = getRowPosition(rowId);
            selected.push({id:rowId,index:position});
            highestIndex=Math.max(highestIndex,position);
            lowestIndex=Math.min(lowestIndex,position);
        }
        selected.sort((a,b)=>a.position - b.position);
        return {
            lowestIndex,
            highestIndex,
            selected
        }
    }
    function onRowHeaderContextMenu(e,rowId){
        if (!getSelectedRows()[rowId]){
            selectRow(e.metaKey,false,rowId);
        }
        showContextMenu({
            pageX : e.pageX,
            pageY : e.pageY,
            items : [
                {text:'Insert rows below',onClick:()=>onCommand('insertRows',{rowId,above:false},'clear')},
                {text:'Insert rows above',onClick:()=>{onCommand('insertRows',{rowId,above:true},'clear');}},
                {text:'Rename row',onClick:()=>{onCommand('renameRow',{row:getRow(rowId)},'clear');}},
                {text:'Delete rows',onClick:()=>onCommand('deleteRows',{rowIds:getSelectedRows()})},                
            ]
        });

    }
    function onColHeaderClicked(controlKey,shiftKey,colId){
        setSelectedRows({});
        var selectedCols = $.extend({},getSelectedCols());
        if (selectedCols[colId]){
            if (controlKey){
                delete selectedCols[colId];
            } else {
                selectedCols = {}
            }
            setSelectedCols(selectedCols);
        } else {
            selectCol(controlKey,shiftKey,colId)
        }
    }
    function getColPosition(colId){

        const cols = gridNote.content.cols;
        for (var i = 0; i < cols.length; i++) { 
            if (cols[i].id==colId){
                return i;
            }
        }
        return - 1;
    }
    function getCol(colId){

        const cols = gridNote.content.cols;
        for (var i = 0; i < cols.length; i++) { 
            if (cols[i].id==colId){
                return cols[i];
            }
        }
        return null;
    }
    function selectCol(controlKey,shiftKey,colId){
        setSelectedRows({});
        var selectedCols = $.extend({},getSelectedCols());
        if (!controlKey && !shiftKey){
            selectedCols={}
        } 
        
            if (shiftKey && objLength(getSelectedCols()) ){
                // get the position of the clicked row
                const clickedIndex = getColPosition(colId);

                // get the top selected row
                const{lowestIndex,highestIndex} = makeSelectedCols();

                if (clickedIndex > highestIndex) {
                    for (var r=highestIndex;r<=clickedIndex;r++){ 
                        selectedCols[ gridNote.content.cols[r].id  ]=true;
                    }
                } else if (clickedIndex < lowestIndex) {
                    for (var r=clickedIndex;r<=lowestIndex;r++){ 
                        selectedCols[gridNote.content.cols[r].id ]=true;
                    }
                }
            } else {
                selectedCols[colId]=!selectedCols[colId];
            }
        
        setSelectedCols(selectedCols);
    }
    function getSoleSelectedCol(){
        const selected = getSelectedCols();  
        const keys = Object.keys(selected);
        if(keys.length==1){
            var selectedKey;
            $.map(keys,key=>{selectedKey=key;});
            return getCol(selectedKey)
        }
    }
    function makeSelectedCols(){
        var highestIndex = -1;
        var lowestIndex = 100000;
        const selected = [];
        for (var colId in getSelectedCols()) { 
            var position = getColPosition(colId);
            selected.push({id:colId,index:position});
            highestIndex=Math.max(highestIndex,position);
            lowestIndex=Math.min(lowestIndex,position);
        }
        selected.sort((a,b)=>a.position - b.position);
        return {
            lowestIndex,
            highestIndex,
            selected
        }
    }
    function onColHeaderContextMenu(e,colId){
        if (!getSelectedCols()[colId]){
            selectCol(e.metaKey,false,colId);
        }
        showContextMenu({
            pageX : e.pageX,
            pageY : e.pageY,
            items : [
                {text:'Insert left',onClick:()=>onCommand('insertColLeft',{colId},'clear')},
                {text:'Insert right',onClick:()=>{onCommand('insertColRight',{colId},'clear');}},
                {text:'Rename',onClick:()=>{onCommand('renameCol',{col:getCol(colId)},'clear');}},
                {text:'Delete columns',onClick:()=>onCommand('deleteCols',{colIds:getSelectedCols()})},                
            ]
        });

    }
    function onToolEvent(data){
        switch (data.key){
            case 'colTags' :{
                setTagsVisible(data.state);
            }break;
        }
    }
    function onMouseUp(e){
        const dragInfo = getColDragInprogress();
        if (dragInfo){

            setColDragInprogress(false);

            const newWidthPx = e.clientX - dragInfo.colLeft;
            const newWidthEm = Math.floor(newWidthPx/dragInfo.oem);

            if (dragInfo.col){
                executeGridCommand('resizeCol',{colId:dragInfo.col.id,newWidth:newWidthEm},poolId);
            } else {
                executeGridCommand('resizeRowHeader',{newWidth:newWidthEm},poolId);
            }
        }
    }
    function onStartColWidthDrag(e,dragInfo){
        setColDragInprogress(dragInfo);
        onColWidthDrag(e);
    }
    function onColWidthDrag(e){
        const dragInfo = getColDragInprogress();
        if (dragInfo){
            const rect= element.current.getBoundingClientRect();
            setColDragBarX(e.clientX-rect.x);
        }
    }
    function onDragEnd(){
        setColDragInprogress(false);
    }

    if ( gridNote ) {

        const rowDefs = gridNote.content.rows;
        const colDefs = gridNote.content.cols;
        rowHeaderWidth = gridNote.content.rowHeaderWidth?gridNote.content.rowHeaderWidth:10;

        const numRows = rowDefs.length;
        const numCols = colDefs.length;

        var filteredColDefs = colDefs;

        if (!getTagsVisible()){
            filteredColDefs = $.grep(colDefs,col=>doTagsPassFilter(col.tags,filter));
        }
        
        const numFilteredColDefs = filteredColDefs.length;

        var rowCells = [];

        var isSelectedRows = Object.keys(getSelectedRows()).length>0

        // Top row
        // corner
        
        rowCells.push(<StickyT.Corner key="topLeft" width={rowHeaderWidth+"em"} >
            <Stack
                direction="horizontal"
                ref = {e=>cornerEle.current=e}
            >
                <div
                    style={{
                        width:'100%'
                    }}
                >
                    <Button onClick={()=>{onCommand('moveRows',{rowIds:getSelectedRows(),delta:-1})}} className="p-1 m-1" size="sm" style={{textTransform: 'none',display:isSelectedRows?'inline':'none',width:'2.5em'}}>Up</Button>
                    <Button onClick={()=>{onCommand('moveRows',{rowIds:getSelectedRows(),delta:1})}} className="p-1 m-1" size="sm" style={{textTransform: 'none',display:isSelectedRows?'inline':'none',width:'2.5em'}}>Dn</Button>

                    <Button onClick={()=>{onCommand('moveColLeft',{col:getSoleSelectedCol()})}} className="p-1 m-1" size="sm" style={{textTransform: 'none',display:getSoleSelectedCol()?'inline':'none',width:'2.5em'}}>Lf</Button>
                    <Button onClick={()=>{onCommand('moveColRight',{col:getSoleSelectedCol()})}} className="p-1 m-1" size="sm" style={{textTransform: 'none',display:getSoleSelectedCol()?'inline':'none',width:'2.5em'}}>Rt</Button>
                </div>
                <div
                    ref = {e=>dragHandleEle.current=e}
                    style={{
                        height :'100%',
                        width:'1em',
                        cursor:'col-resize'
                    }}
                    onMouseDown={e=>{
                        const rectHeader = cornerEle.current.getBoundingClientRect() 
                        const rectHandle = dragHandleEle.current.getBoundingClientRect() 
                        onStartColWidthDrag(e,{col:null,colLeft:rectHeader.x,oem:rectHandle.width});
                    }}
                >
                    &nbsp;
                </div>
            </Stack>


            
        </StickyT.Corner>) 

        // col headers
        const selectedCols = getSelectedCols();

        for (var c = 0;c<numFilteredColDefs;c++){
            var col = filteredColDefs[c];
            rowCells.push(<ColHeader
                key={col.id}
                col = {col}
                style={{
                    zIndex:25,
                    padding:'0.3em',
                    paddingRight:'0',
                    // minHeight : '5em',
                }}
                onClick={onColHeaderClicked} onContextMenu={onColHeaderContextMenu}
                selected = {selectedCols[col.id]}
                onStartColWidthDrag={onStartColWidthDrag}
            />)
        
        }

        headerRow = <tr key='colHeader'
            style={{
                // minHeight : '5em',
            }}
        >
            {rowCells}
        </tr>

        rows = [];

        // col tag row

        if (getTagsVisible()){

            rowCells = [];

            rowCells.push(<StickyT.RowHeader key="tagRowHeader" />);

            for ( var c=0 ; c<numFilteredColDefs ; c++ ) {
                var col = filteredColDefs[c];
                (function(c,col){

                    rowCells.push( <StickyT.Cell
                        key = {col.id}
                        style={{
                            verticalAlign:'top',
                        }}
                    >
                        <TagSelector
                            availableTags={gridNote.content.tags}
                            tags={col.tags}
                            onChanged={tags=>{onCommand('updateColTags',{col,tags});}}
                        />
                    </StickyT.Cell>)

                })(c,col)
            }


            rows.push(<tr key={'tagSelections'} style={{}}>
                {rowCells}
            </tr>)
        }

        // Content rows

        for (var r = 0 ; r<numRows ; r++){
            var row = rowDefs[r];

            rowCells =[];

            rowCells.push(<RowHeader key="rowHeader" keyId='rowHead' poolId = {poolId} row={row} selected={getSelectedRows()[row.id]} onClick={onRowHeaderClicked} onContextMenu={onRowHeaderContextMenu}/>);

            for ( var c=0 ; c<numFilteredColDefs ; c++ ) {
                var col = filteredColDefs[c];
                (function(c,r,col,row){

                    const cell =  cellDefs[makeCellKey(col.id,row.id)];
                    
                    function onNewNote(noteType){
                        const content = makeEmptyNoteContent(noteType);
                        content.rowId = row.id;
                        content.colId = col.id;
                        newNote (noteType,poolId,{},content);
                    }

                    rowCells.push(<GridNoteTableCell
                        key = {col.id}
                        onNewNote = {onNewNote}
                        col = {col}
                        row ={row}
                        cellNoteId ={cell}
                        style={{}}
                        poolId ={poolId}
                    />)

                })(c,r,col,row)
    
            }

            rows.push(<tr key={row.id} style={{}}>
                {rowCells}
            </tr>)
        }

    }

    style = $.extend({},style,{
        width:'100%',
        height:'100%',
        overflowY:'auto',
        position:'relative',
    });

    
    return (<div
        style = {style}
        className = {className}
        id = "Grid"
        ref={e=>element.current=e}
        onMouseUp = {onMouseUp}
        onMouseMove = {onColWidthDrag}
        onMouseLeave = {onDragEnd}
    >

        <StickyT.Table
            style={{
                position:'relative',    // need to make the table a stack context
                zIndex:1,               // so I can control the z order of sub components
            }}
        >
            <thead>
                {headerRow}
            </thead>
            <tbody>
                {rows}
            </tbody>
        </StickyT.Table>
    
        <div
            id='colWidthDragBar'
            style={{
                height:'100%',
                width:'0.5em',
                backgroundColor:'#E0E0E0',
                position:'absolute',
                top:'0px',
                left:getColDragBarX(),
                zIndex:100,
            }}
            className={getColDragInprogress()?"":"d-none"}
        >
            &nbsp;
        </div>
    </div>)

}
