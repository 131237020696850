
import { getPoolTags, noteComparer, ParagraphList } from "./notes"
import { useSelector } from "react-redux";
import { useEffect ,useRef,useState,useContext} from "react"
import { sendCommand } from "../Command"
import { Button, Stack ,Container,Row ,Dropdown,ButtonGroup} from "react-bootstrap";
import $ from 'jquery';

import { pageContext,browserContext } from "../pageContext";
import { PoolFilter } from './tags'
import { isString, isUndef, ToggleButton, useEventSource, useMount, useReadableState, useResettableProp } from "../general";
import { getAvailableTags } from "./notes";
import { isDef } from "../general";
import { CheckBox } from "../general";
import { GridPage } from "./gridNote";
import react from 'react';

import StickyT from "../stickyTable2";

export function ToolColumn({className,tools,bodyButtonsVisible,fireToolEvent,style,onOpenAllBodies,onCloseAllBodies,onTagFilterVisibilityChanged}){

    const [tfv,setTFV]= useState(false);

    style = $.extend({},style,{});

    const btnStyle = {
        width:'3em'
    }
    const btnClass = "px-0 m-1"

    function onTFVChanged(e){
        const result = e.target.value;
        setTFV(result);
        onTagFilterVisibilityChanged(result);
    }

    const toolButtons = [
        <div key="filter" ><ToggleButton value = {tfv} onChanged={onTFVChanged} className={btnClass} style={btnStyle} text="T" /></div>,
    ];

    if (bodyButtonsVisible){
        toolButtons.push(<div key="opeAll" ><Button onClick={onOpenAllBodies} className={btnClass} style={btnStyle}  >B+</Button></div>);
        toolButtons.push(<div key="closeAll" ><Button onClick={onCloseAllBodies} className={btnClass} style={btnStyle} >B-</Button></div>);
    }
    tools = tools?tools:[];
    
    tools.map(tool=>{
        var button = null;
        if (tool.type=='checkbox'){
            button = <div key={tool.key} ><ToggleButton 
                initialValue = {!!tool.initialState} 
                onChanged={ e=>{fireToolEvent({ key:tool.key , state:e.target.value} )} } 
                className={btnClass} 
                style={btnStyle} 
                text={tool.name}
            /></div>
        } else {
            button = <div key={tool.key} ><Button 
                onClick={ ()=>fireToolEvent({key:tool.Key}) } 
                className={btnClass} 
                style={btnStyle} 
            >
                    {tool.name}
            </Button></div>
        }   
        toolButtons.push(button);
    });


    return <Stack
        style={style}
        className = {className+" me-2"+""}
        direction = 'vertical'
        id = "toolColumn"
    >
        {toolButtons}
    </Stack>

}

export function PoolPage({poolId,externalHeight,style,className}){


    useEffect(()=>{
        // console.log("Mounting pool inner " + poolId);
    });
    function PoolInner({poolId,filter,bodiesOpen,style,className}){

        const {addNewNoteToPage} = useContext(pageContext);

        function createNewNote(type){
            var newTags = {};
            if (filter){
    
                for (var tagKey in filter){
                
                    var f = filter[tagKey];
                    if (f) {
                        // if (f.or){
                        //     ors=ors?ors:{};
                        //     ors[tagKey]=true;
                        // }
                        if (f.and){
                            newTags[tagKey]=tagKey;
                        }
                        // if (f.not){
                        //     nots=nots?nots:{};
                        //     nots[tagKey]=true;
                        // }
                    }
                }
                
            }
    
            var tags = JSON.stringify(newTags);

            addNewNoteToPage(type,poolId,tags);
        }

        style = $.extend({},style,{});
        return <div
            style = {style}
            className = {className}
        >
            <ParagraphList 
                className= "me-2"
                parentNoteId={poolId} 
                filter={filter} 
                bodiesOpen = {bodiesOpen}
                style={{
                    width:'100%'
                }}
            />
            
            {/* new note split button */}

            <Dropdown as={ButtonGroup} className="my-2">
                <Button className="" onClick={()=>{createNewNote('basic')}} >New Note</Button>

                <Dropdown.Toggle split  id="dropdown-split-basic" />

                <Dropdown.Menu>
                    <Dropdown.Item onClick={()=>{createNewNote('basic')}} >New Basic Note</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{createNewNote('pool')}}>New Pool</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{createNewNote('grid')}}>New Grid</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    }

    return <Page
        style = {style}
        className = {className}
        Inner = {PoolInner}
        poolId = {poolId}
        externalHeight = {externalHeight}
        bodyButtonsVisible = {true}
    />
}

export function Page({Inner,bodyButtonsVisible,tools,poolId,externalHeight,className,style}){

    const [getFilter,setFilter]= useReadableState({});
    const nextNotesKey = useRef(0);
    const [getTFVvisible,setTFVvisible]= useReadableState(false);

    const [getPoolId,setPoolId] = useReadableState(poolId)
    const [getBodiesOpen,setBodiesOpen] = useReadableState(true);
    
    const bc = useContext(browserContext);
    const {gotoPool,newNote} = bc?bc:{gotoPool:null,newNote:null};

    const [toolEvent,fireToolEvent] = useEventSource();

    const loaded = useRef(false);

    const filter = getFilter();
    const tfvVisible = getTFVvisible();
    
    externalHeight = !!externalHeight;

    useMount(()=>{
        // console.log("Mounting page "+ poolId);
        if (!loaded.current){
            sendCommand({
                objectType : 'Pool',
                verb : 'fetch',
                parentNoteId : poolId,
                filter : ''
            });
            loaded.current = true;
        }
    })

    const poolNote = useSelector(state => {
        var poolNote = null;
        if (state && state.model && state.model.notes){
            poolNote = state.model.notes[getPoolId()]
        }
        return  poolNote;
    },(a,b)=>{
        return noteComparer(a,b)
    });

    function onFilterChanged(filter){
        nextNotesKey.current++;
        setFilter(filter);
    }

    const pageContextProvider= {

        

        addNewNoteToPage (noteType,parentNoteId,tags){

            // root this through here because I might want to add page specific stuff later
            newNote(noteType,parentNoteId,tags);

        
        }
    }

    const parentId = poolNote?poolNote.parent:-1;

    function onBack(){
        gotoPool(parentId);
    }

    var bodiesOpenSignal = useRef(1);

    const inner = <Inner
        poolId={getPoolId()}
        filter={filter}
        bodiesOpen ={getBodiesOpen()}
        style={{
            width:'100%',
            height:'100%',
        }}
        toolEvent = {toolEvent}
        id = "inner"
    />

    // if ( externalHeight ) {
    //     style = $.extend({},style,{
    //         height : '100vh',
    //     })    
    // }

    style = $.extend({},style,{
        width : '100%',
        height : '100%',
        display:'flex',
        
        //backgroundColor :externalHeight?'red':'green'
    })
    
    if (externalHeight){
        style.height = '100%'
    }

    // if (externalHeight){
    //     className = className + " position-absolute"
    // }

    return (<div
        className= {className + " "}
        style={style}
        id = 'page'
    >

        <pageContext.Provider value={pageContextProvider}  >
            
            
                <ToolColumn
                    className=""
                    style = {{
                        width:'3em',
                        flexGrow: 0,
                        flexBasis:'3em'
                    }}
                    bodyButtonsVisible = {bodyButtonsVisible}
                    onCloseAllBodies = {()=>setBodiesOpen(-(bodiesOpenSignal.current++))}
                    onOpenAllBodies = {()=>setBodiesOpen(bodiesOpenSignal.current++)}
                    onTagFilterVisibilityChanged = {tfVisible=>{setTFVvisible(tfVisible);}}
                    tools = {tools}
                    fireToolEvent = {fireToolEvent}
                />

                {/*key changes and filter get remounted every time root pool id changes*/}
                <PoolFilter 
                    parentNoteId={getPoolId()} 
                    value = {filter}
                    onFilterChanged={onFilterChanged}
                    style = {{
                        width:'10em',
                        flexGrow: 0,
                        flexBasis:'10em'
                    }}
                    // style = {{display:'table-cell',width:'10em'}}
                    className = {(tfvVisible?"":"d-none")+" me-2"}
                    key = {getPoolId()} 

                    
                />

                <div 
                    id = "thirdColumn"
                    className='pe-2' 
                    style={{
                        overflowX:'hidden', // stops content from stretching the flex box
                        maxWidth : '100%',
                        flexGrow: 2,
                        height : '100%',
                        overflowY : (externalHeight)?'auto':'visible',
                    }}
                >
                    
                    {/* pool title */}
                    <Stack 
                        className="" 
                        style={{
                            
                        }}
                        direction="horizontal"
                    >
                        <Button
                            className = {((parentId>=0)?"":"d-none")+" me-2"}
                            onClick = {onBack}
                            size = 'sm'
                            style={{
                              
                            }}
                        >Back</Button>
                        <span style={{fontSize:'2em'}}>{poolNote?poolNote.title:"loading"}</span>
                    </Stack>

                    {/* <div style={{}}></div> */}
                
                    {/* inner */}
                    {inner}
                    
                    {/* </div><div> */}
                </div>

            {/* </div> */}
            
        </pageContext.Provider>

    </div>)


    // return (<div
    //     className= {className + " "}
    //     style={style}
    //     id='page'
    // >

    //     <pageContext.Provider value={pageContextProvider}  >
            
    //         <div className=""
    //             style={{
    //                 display: "table",
    //                 tableLayout : 'fixed',
    //                 height : '100%',
    //                 width : '100%',
    //             }}
    //             id = 'ThreeColumns'
    //             direction="horizontal"
    //         >
    //             <ToolColumn
    //                 className=""
    //                 position = 'sticky'
    //                 style = {{display:'table-cell',width:'3em'}}
    //                 onCloseAllBodies = {()=>setBodiesOpen(-(bodiesOpenSignal.current++))}
    //                 onOpenAllBodies = {()=>setBodiesOpen(bodiesOpenSignal.current++)}
    //                 onTagFilterVisibilityChanged = {tfVisible=>{setTFVvisible(tfVisible);}}
    //                 tools = {tools}
    //                 fireToolEvent = {fireToolEvent}
    //             />

    //             {/*key changes and filter get remounted every time root pool id changes*/}
    //             <PoolFilter 
    //                 parentNoteId={getPoolId()} 
    //                 value = {filter}
    //                 onFilterChanged={onFilterChanged}
    //                 style = {{display:'table-cell',width:'10em'}}
    //                 className = {(tfvVisible?"":"d-none")+" me-2"}
    //                 key = {getPoolId()} 
    //             />

    //             <div 
    //                 className='pe-2' 
    //                 style={{
    //                     display:'table-cell',
    //                     // position:'relative',
    //                     width : '100%',
    //                     overflow : (externalHeight)?'auto':'visible'
    //                 }}
    //             >
                    
    //                 {/* pool title */}
    //                 <Stack 
    //                     className="" 
    //                     style={{
                            
    //                     }}
    //                     direction="horizontal"
    //                 >
    //                     <Button
    //                         className = {((parentId>=0)?"":"d-none")+" me-2"}
    //                         onClick = {onBack}
    //                         size = 'sm'
    //                         style={{
                              
    //                         }}
    //                     >Back</Button>
    //                     <span style={{fontSize:'2em'}}>{poolNote?poolNote.title:"loading"}</span>
    //                 </Stack>

    //                 {/* inner */}
    //                 <div style={{}}></div>
                
    //                 {inner}
                    
    //                 </div><div>
    //             </div>

    //         </div>
            
    //     </pageContext.Provider>

    // </div>)


}


export function makeEmptyNoteContent(noteType){
    return {
        basic : {body:""},
        pool : {tags:{}},
        grid : {tags:{},cols:[{name:"Empty",id:1,width:10}],rows:[{name:"Empty",id:1}],rowHeaderWidth:10}
    }[noteType];
}

export function removePoolFromModel(model,parentNoteId){
    if (model && model.notes){
        const notes = {};

        for (var noteId in model.notes){
            var note = model.notes[noteId];
            if (note.id != parentNoteId && note.parent != parentNoteId){
                notes[noteId] = note;
            }
        }
        model.notes = notes;
    }
}

export function NoteBrowser({poolId}){

    const [getPoolId,setPoolId] = useReadableState(poolId);

    useEffect(()=>{
        // console.log ("remounting Note browser with pool " + getPoolId())
        browserContextProvider.gotoPool(getPoolId());
        sendCommand({
            objectType : 'Note',
            verb : 'fetch',
            noteId : getPoolId()
        });
    });

    const poolNote = useSelector(state => {
        var poolNote = null;
        if (state && state.model && state.model.notes){
            poolNote = state.model.notes[getPoolId()]
        }
        return  poolNote;
    },noteComparer);

    var PageComponentType = null;

    if (poolNote){
        PageComponentType = {
            basic : null,
            pool : PoolPage,
            grid : GridPage
        }[poolNote.type]
    }

    const page = PageComponentType?<PageComponentType 
        poolId = {getPoolId()}  
        key = {getPoolId()}  
        externalHeight = "true"
    />:null;

    const browserContextProvider= {

        gotoPool (poolId){
            // setFilter({});\
            // sendCommand({
            //     objectType : 'Pool',
            //     verb : 'fetch',
            //     parentNoteId : poolId,
            //     filter : ''
            // });
            
            setPoolId(poolId);
        },

        newNote (noteType,parentNoteId,tags,content){

            content = content?content:makeEmptyNoteContent(noteType);
            content = JSON.stringify(content);

            tags = tags?tags:"{}";
            tags = isString(tags)?tags:JSON.stringify(tags);
            sendCommand({
                actions: [
                    {
                        objectType : 'Note',
                        verb : 'create',
                        poolId : parentNoteId,
                        type : noteType,
                        content : content,
                        tags : tags,
                    },{
                        objectType : 'Pool',
                        verb : 'fetch',
                        parentNoteId : parentNoteId,
                        filter : ''
                    }
                ],
                clearModel : function(model){
                    removePoolFromModel(model,parentNoteId);
                }
            });

        }
    }

    return <div
        style={{
            width : '100%',
            height : '100%'
        }}
    >
        <browserContext.Provider value={browserContextProvider}  >
            
            {page}

        </browserContext.Provider>
    </div>


}


