
import { configureStore } from '@reduxjs/toolkit';
import $ from 'jquery';
import {isArray, isBool, isString} from './general';

export const store = configureStore({
    
    reducer: {
      
        model: (model ={forced:{}} , action) => {
            switch (action.type) {
            case 'modelUpdate':
    
                return modelUpdate(model,action);
    
            case 'force' :  
    
                return force(model,action);
                
            case 'unforce' :
    
                return unforce(model,action);
    
            default:
                return model;
            }
        },

        overlay : (overlay = null , action) => {
            switch (action.type) {
                case 'setOverlay':
                    return action.overlay?action.overlay:null;
        
                default:
                    return overlay;
            }
        },

        contextMenu : (contextMenu = null , action) => {
          switch (action.type) {
              case 'setContextMenu':
                  return action.contextMenu?action.contextMenu:null;
      
              default:
                  return contextMenu;
          }
      },

    },

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['setOverlay','setContextMenu','modelUpdate'],
          // // Ignore these field paths in all actions
          ignoredActionPaths: ['overlay','contextMenu','modelUpdate','clearModel'],
          // // Ignore these paths in the state
          ignoredPaths: ['overlay','contextMenu'],
        },
      })
  
  });

const forceableFetchers={
    notes : function(model,uId){

    },
    tags : function(model,uId){
        
    }
}

// user , type_index
function makeForcedKey(type,uId){
    return type+"_"+uId;
}

export function forceObject(forceType,uId,value){
    store.dispatch({
        type : 'force',
        forceType ,
        uId,
        value
    })
}

export function unforceObject(forceType,uId){
    store.dispatch({
        type : 'unforce',
        forceType ,
        uId
    })
}

function unforce(model,action){
    
    const { uId , forceType } = action;
    const newModel = $.extend(true, {}, model);
    const forcedKey = makeForcedKey(forceType,uId);
    var forced = newModel.forced[forcedKey];
    if (forced){
        forced.count--;
        if (forced.count<=0){
        delete newModel.forced[forcedKey];
        }
    }
    return newModel;
}

function force(model,action){

    const { uId , forceType, value } = action;

    const forcedKey = makeForcedKey(forceType,uId);

    var newModel = $.extend(true, {}, model);
    var forced = newModel.forced[forcedKey];
    if (forced){
      forced.count++;
      forced.value = value;
    } else {
      forced = {value:value,count:1};
    }
    newModel.forced[forcedKey] = forced;

    return newModel;
}

export function getForced(model,forceType,uId){
    const forcedKey = makeForcedKey(forceType,uId);
    var forced = model.forced[forcedKey];
    if (forced){
      return forced.value;
    }
    return forceableFetchers[forceType](model,uId);
}

function makeEmptyModel (){
  return {
    forced:{}
  };
}

const modelObjectAdapters = {
    //ChatLine : (chatLine)=>{ chatLine.at = new Date(chatLine.at);  }
    "notes" : (note=>{
        if (isString(note.content)){
            note.content=note.content?JSON.parse(note.content):undefined;
        }
        if (isString(note.tags)){
            note.tags = note.tags?JSON.parse(note.tags):{};
        }
    })
}

function adaptModeObjects(key,objects){

    const adapter = modelObjectAdapters[key];
    if (adapter){
        $.map(objects,obj=>{
            return adapter(obj)
        });
    }

}

export function updateStoreModelObject(objectType,id,value){
    
    const update = {};
    update[id] = value;
    const modelUpdates = {};
    modelUpdates[objectType]=update;

    var action = {
        type : 'modelUpdate',
        modelUpdates :modelUpdates,
        clearModel : false
    };

    store.dispatch(action);

}

function modelUpdate(model,action) {

  var newModel = $.extend(true, {}, model);
  
  if (action.clearModel){

    if (action.clearModel=='*'){
      newModel = makeEmptyModel();
    } else {
      //newModel = $.extend(true, {}, model);

      var clearStacks = action.clearModel;
      if (!isArray(action.clearModel)){
        clearStacks = [action.clearModel];
      }
      clearStacks.map(objectType=>{
        if (isString(objectType)){
          delete newModel[objectType];
        } else {
          objectType(newModel);
        }
      });
    }
  }

  // if (isBool(action.clearModel) && action.clearModel){
  //     newModel = makeEmptyModel();
  // } else {
  //     newModel = $.extend(true, {}, model);
  //     if (action.clearModel){
  //       switch (action.clearModel){
  //         case "eventEdos":{
  //           if (newModel.EventDateOption){
  //             newModel.EventDateOption = $.grep(newModel.EventDateOption,edo=>edo.event!=action.eventId);
  //           }
  //         }break;
  //       }
  //       // action.clearModel(newModel);
  //     }
  // } 

  for (var updateKey in action.modelUpdates){

    var update =  action.modelUpdates[updateKey];

    adaptModeObjects(updateKey,update);

    if (isArray(update)){
      newModel[updateKey] = update;
    } else {
      if (!newModel[updateKey] ){
        newModel[updateKey]={}
      }
      $.extend( newModel[updateKey] ,update);
    }

  }

  return newModel;
}



