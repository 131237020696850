import { isBool , isString , isArray} from './general';
import { store } from './tagnoteStore';

import React, { } from 'react';
import { useSelector} from 'react-redux';
import {Container,Row,Col,Button,Modal} from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';

import $ from 'jquery';


export function showContextMenu(contextMenu){
    store.dispatch({
        type : 'setContextMenu',
        contextMenu : contextMenu
    });
  }
  
export function hideContextMenu(){
    store.dispatch({
        type : 'setContextMenu',
        contextMenu : null
    });
  }

export function ContextMenu() {
  
    const contextMenu = useSelector(state => {
        return state.contextMenu
    });

    const show = (!!contextMenu);
  
    function close(){
        hideContextMenu();
    }

    // function onOk(){
    //     close();
    //     if (overlay.onOk){
    //         overlay.onOk();
    //     }
    // }

    if (contextMenu){

        // message = contextmenu.message

        // if (isArray(message)){
        //     message = $.map(message,m=>{return React.createElement('p',{},m)})
        // } else if (!isString(message)){
        //     message = React.createElement(message.tag,message.props);
        // }


        // if (overlay.title){
        //     header = (
        //         <Modal.Header className="bg-primary text-white" show="false" key="1" >
        //             <Modal.Title>{overlay.title}</Modal.Title>
        //         </Modal.Header> 
        //     );
        // }
        
        // if (overlay.cancel || overlay.ok){
        //     var buttons = [];
        //     if (overlay.ok){
        //         buttons.push (<Button variant="outline-primary" size="sm" style = {{ textTransform: 'none'}} onClick={onOk}  key="1" >
        //             {(isBool(overlay.ok))?'OK':overlay.ok}
        //         </Button>);
        //     }
        //     if (overlay.cancel){
        //         buttons.push (<Button variant="outline-primary" size="sm" style = {{ textTransform: 'none'}} onClick={onCancel}  key="2">
        //             {(isBool(overlay.cancel))?'Cancel':overlay.cancel}
        //         </Button>);
        //     }
        //     footer = (
        //         <Modal.Footer  key="3">
        //             {buttons}
        //         </Modal.Footer>
        //     )
        // }
        
        

    }

    var nextItemKey = 1;

    const items = contextMenu?contextMenu.items.map(item=>{

        return <Dropdown.Item 
            key={nextItemKey++}
            onClick = {e=>{
                item.onClick(e);
                close();
            }}
        >
            {item.text}
        </Dropdown.Item>

        return 
    }):[];
    



    const pageX = contextMenu?contextMenu.pageX:0;
    const pageY = contextMenu?contextMenu.pageY:0;

    const top = pageY+"px";
    const left = pageX+"px";
    
    return (
  
        // <Modal show={show} style={{}} animation={false} static={false}>          
        <div
            style={{
                position:'fixed',
                zIndex:100,
                top:'0px',
                left:'0px',
                width:'100vw',
                height:'100vh',
                // backgroundColor:'yellow',
                visibility : show?'visible':'hidden'
            }} 

            onMouseDown = {(e)=>{
                if (e.target === e.currentTarget){
                    close()
                }
            }}
        >  

            <div 
                className='rounded-5 shadow'
                style ={{
                    position : 'absolute',
                    top:top,
                    left : left,
                    minWidth : '10em',
                    backgroundColor:'#FFFFFF'
                }}
            >
                {items}
            </div>

        </div>
    );
  }