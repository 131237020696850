import {checkMin,checkMax,checkAlowedLetters_label,checkEmailAddress,checkMatch,checkAlowedLetters_password} from './general';
// import { store } from './shedStore';
import {BaseContext} from './baseContext';

import React, { useEffect, useRef,useState ,useContext} from 'react';
// import { useSelector} from 'react-redux';
import {InputGroup,Form,Col,Button} from 'react-bootstrap';
import { showDialog,hideDialog } from './ModalDialog';

// import $ from 'jquery';

export function ConfirmEmail(props) {
    
    const {securityToken}  = props;

    const firstTime = useRef(true);

    const { command , showDialog ,onRenterFrontEnd ,onPasswordResetRequested, onEmailConfirmationNeedsPassword } = useContext(BaseContext);

    useEffect(()=>{
        if (firstTime.current){
            firstTime.current = false;

            command({
                objectType : 'Account' , verb : 'confirmEmail',
                securityToken : securityToken,
                onSucceeded (report){
                    if (report.ok){
                        showDialog({
                            title : 'Success',
                            message : 'Thankyou for confirming your email.',
                            ok : true,
                            onOk : onRenterFrontEnd
                        });
                    } else if (report.error == 'passwordNeeded'){
                        showDialog({
                            title : 'One more step.',
                            message : 'You just need to create a password for this account.',
                            ok : 'Create password',
                            cancel : true,
                            onCancel : onRenterFrontEnd,
                            onOk : ()=> onEmailConfirmationNeedsPassword(securityToken)
                        });
                    } else {
                        showDialog({
                            title : 'There was a problem.',
                            message : 'You will have to request another password reset.',
                            ok : 'Reset password',
                            onOk : ()=> onEmailConfirmationNeedsPassword(securityToken),
                            cancel : true,
                            onCancel : onPasswordResetRequested
                        });
                    }
                }
            })


        }
    });

    return (
        <div>

        </div>
    )
}

export function Logon(props){

    const { command , onLogonSucceeded , onPasswordResetRequested , onNewAccountRequested,showFieldValidation , onVerificationEmailResendRequested } = useContext(BaseContext);

    const email = useRef('');
    const password = useRef('');
    
    function onSucceeded (logonResult) {
                
        if (logonResult.report.ok){
            onLogonSucceeded(logonResult.userId,logonResult.sessionToken);
        } else if (logonResult.report.error = 'userNotFound'){
            showDialog({
                message : 'User not found.',
                cancel : 'Close'
            })
        } else if (logonResult.report.error = 'unverified'){
            showDialog({
                title : 'Logon failed.',
                message : ['That account exists but has not been verifed yet.','You should have recieved an eamail with a verification link.'],
                cancel : 'Close',
                ok : 'Resend verification email',
                onOk : ()=>{
                    onVerificationEmailResendRequested( email.current.toLowerCase())
                }
            });
        } else {
            showDialog({
                message : 'Logon failed.',
                cancel : 'Close'
            })
        }

    }

    const logginClicked = ()=>{

        if ( showFieldValidation ([
            ['Email',email.current.trim(),checkMax(30),checkEmailAddress()],
            ['Password',password.current,checkMin(4),checkMax(30),checkAlowedLetters_password()],
        ])){
            command({
                actions:[{
                    objectType : 'Account', verb : 'logon',
                    email : email.current.toLowerCase(),
                    password : password.current ,
                    modal : true,
                    onSucceeded : onSucceeded
                }],
                modal : true
            })
        }
    }

    const createAccountClicked = ()=>{
        onNewAccountRequested(email.current);
    }
    const requestPasswordResetClicked = ()=>{
        onPasswordResetRequested(email.current);
    }

    return (


         <div className="container h-100 p-0" >
            
            <div className="container position-absolute top-50 start-50 translate-middle" 
            style={{maxWidth:'30em'}}
            >
                <div className="panel-body">
                    <h1>Login </h1>
                    <InputGroup className="w-100 my-2 mr-2" >

                        <InputGroup.Text id="in3" style={{width:hw}} onChange={e => {email.current = e.target.value;}}  >Email</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Your email"
                            onChange={e => {email.current = e.target.value;}} 
                            aria-label="email"
                            aria-describedby="in3"
                        />
                    </InputGroup>
                    <InputGroup className="w-100 my-2 mr-2" >
                        <InputGroup.Text id="in4" style={{width:hw}}   >Password</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Your password"
                            onChange={e=>{password.current=e.target.value}} 
                            aria-label="password"
                            aria-describedby="in4"
                        />
                    </InputGroup>
                    <button type="button" onClick={logginClicked} className="btn btn-primary mr-2 m_b2 d-block" >Login</button>
                    <button type="button" onClick={requestPasswordResetClicked} className="btn btn-link my-2 mr-2" style={{textTransform: 'unset' }} >Forgotten Password</button>
                    <button type="button" onClick={createAccountClicked} className="btn btn-link d-inline" style={{textTransform: 'unset' }}>Create New Account</button>
                </div>
            </div>

            
        </div>
    )
}

export function PasswordResetRequest(props){

    const {command,onRenterFrontEnd,showFieldValidation} = useContext(BaseContext);

    const email = useRef('');

    function onOk(){

        if ( showFieldValidation ([
            ['Email',email.current.trim(),checkMax(30),checkEmailAddress()],
        ])){

            command({
                objectType : 'Account' , verb:'resetPassword',
                email : email.current,
                onSucceeded : (report=>{
                    if (report.ok){
                        showDialog({
                            title : 'Success',
                            message : 'An email with a link to reset your password was sent to '+ email.current,
                            ok :true,
                            onOk : onRenterFrontEnd
                        })
                    } else if (report.error=='notFound'){
                        showDialog({
                            title : 'Account not found.',
                            message : "No account with the email " + email.current + " was found.",
                            ok :true
                        })
                    }
                })
            })

        }
    }

    return (<div className="container h-100 p-0" >
            
        <div className="container position-absolute top-50 start-50 translate-middle" 
        style={{maxWidth:'30em'}}
        >
            <div className="panel-body">
                <h1>Reset Password</h1>                           
                <span>Please enter your account's email address.</span>    
                <InputGroup className="w-100 my-2 mr-2" >
                    <InputGroup.Text id="in5"  style={{width:hw}}>Email</InputGroup.Text>
                    <Form.Control
                        type="text"
                        onChange={e=>{email.current=e.target.value}}
                        placeholder="Re-enter password"
                        aria-label="re-enter password"
                        aria-describedby="in5"
                    />
                </InputGroup>
                <button type="button" onClick={onRenterFrontEnd} className="btn btn-primary d-inline mr-2 m_b2" style={{width:'6em'}} >Cancel</button>
                <button type="button" onClick={onOk} className="btn btn-primary d-inline mx-2" style={{width:'6em'}} >Ok</button>
            </div>
        </div>

    </div>)
}

export function ChooseNewPassword(props){

    const {userId,securityToken} = props;

    const {onRenterFrontEnd,command,showFieldValidation} = useContext(BaseContext);
    const password = useRef('');
    const passwordR = useRef('');

    function onOk (){
        if ( showFieldValidation ([
            ['Password',password.current,checkMin(4),checkMax(30),checkAlowedLetters_password()],
            ['Passwords',password.current,checkMatch(passwordR.current)]
        ])){
            command({
                objectType : 'Account' , verb : 'setPassword',
                value : password.current,
                securityToken : securityToken,
                onSucceeded : (report)=>{
                    if (report.ok){
                        showDialog({
                            title : 'Success',
                            message : 'Your password has been reset',
                            ok :true,
                            onOk : onRenterFrontEnd
                        })
                    } else {
                        showDialog({
                            title : 'There was a problem',
                            message : 'Please try a different password.',
                            ok :true
                        })
                    }
                }
            })
        }
    }
    return (
        <div className="container h-100 p-0" >
            
            <div className="container position-absolute top-50 start-50 translate-middle" 
            style={{maxWidth:'30em'}}
            >
                <div className="panel-body">
                    <h1>Set Password</h1>                    
                    <InputGroup className="w-100 my-2 mr-2" >
                        <InputGroup.Text id="in4" style={{width:hw}}   >Password</InputGroup.Text>
                        <Form.Control
                            type="text"
                            onChange={e=>{password.current=e.target.value}} 
                            placeholder="Reqired"
                            aria-label="password"
                            aria-describedby="in4"
                        />
                    </InputGroup>
                    <InputGroup className="w-100 my-2 mr-2" >
                        <InputGroup.Text id="in5"  style={{width:hw}}  >Re-enter</InputGroup.Text>
                        <Form.Control
                            type="text"
                            onChange={e=>{passwordR.current=e.target.value}}
                            placeholder="Re-enter password"
                            aria-label="re-enter password"
                            aria-describedby="in5"
                        />
                    </InputGroup>
                    <button type="button" onClick={onRenterFrontEnd} className="btn btn-primary d-inline mr-2 m_b2" style={{width:'6em'}} >Cancel</button>
                    <button type="button" onClick={onOk} className="btn btn-primary d-inline mx-2" style={{width:'6em'}} >Ok</button>
                </div>
            </div>

            
        </div>

    );
}
// position-absolute top-50 start-50 translate-middle

const hw = '6em';
export function CreateNewAcount(props){

    const {handlers} = props;

    const { onRenterFrontEnd , command , showDialog , onNewAccountCreated,showFieldValidation ,onVerificationEmailResendRequested,onPasswordResetRequested} = useContext(BaseContext);

    const name = useRef('');
    const email = useRef('');
    const password = useRef('');
    const passwordR = useRef('');

    function onOk() {

        if ( showFieldValidation ([
            ['Name',name.current.trim(),checkMin(5),checkMax(30),checkAlowedLetters_label()],
            ['Email',email.current.trim(),checkMax(30),checkEmailAddress()],
            ['Password',password.current,checkMin(4),checkMax(30),checkAlowedLetters_password()],
            ['Passwords',password.current,checkMatch(passwordR.current)]
        ])){
            const niceEmail = email.current.toLowerCase().trim();

            command( {
                    objectType : "Account" , verb : "create",
                    email : email,
                    name : name.current.trim() ,
                    password : password.current ,
                    onSucceeded : (result)=>{
                        if (result.ok){
                            onNewAccountCreated( niceEmail );
                        } else if (result.error == "exists"){ // existing account must be verified
                            showDialog({
                                title : 'Failed',
                                message : 'An account with that email already exists : '+ niceEmail ,
                                cancel: 'Close',
                                ok : 'Logon.',
                                onOK : onRenterFrontEnd,
                                other : 'Reset password',
                                onOther : onPasswordResetRequested
                            });
                        } else if (result.error == "unverified"){
                            showDialog({
                                title : 'Failed to create account.',
                                message : ["An account with that email already exists but it has not been verrified","You should have received an email with a verrification link."],
                                cancel: 'Close',
                                ok : 'Resend verification email.',
                                onOk : ()=>{onVerificationEmailResendRequested(niceEmail)}
                            });
                        } else {
                            showDialog({
                                title : 'Failed to create account.',
                                message : "We could not create an account with those details.",
                                cancel: 'Close'
                            });
                        }
                    }
                } 
            );
        }
    }

    return (
        <div className="container h-100 p-0" >
            
            <div className="container position-absolute top-50 start-50 translate-middle" 
            style={{maxWidth:'30em'}}
            >
                <div className="panel-body">
                    <h1>New Account </h1>
                    <InputGroup className="w-100 my-2 mr-2" >
                        <InputGroup.Text id="in2" style={{width:hw}}>Name</InputGroup.Text>
                        <Form.Control
                            type="text"
                            onChange={e=>{name.current=e.target.value}} 
                            placeholder="Reqired"
                            aria-label="name"
                            aria-describedby="in2"
                        />
                    </InputGroup>
                    <InputGroup className="w-100 my-2 mr-2" >
                        <InputGroup.Text id="in3" style={{width:hw}}  >Email</InputGroup.Text>
                        <Form.Control
                            onChange={e=>{email.current=e.target.value}} 
                            type="text"
                            placeholder="Reqired"
                            aria-label="email"
                            aria-describedby="in3"
                        />
                    </InputGroup>
                    <InputGroup className="w-100 my-2 mr-2" >
                        <InputGroup.Text id="in4" style={{width:hw}}   >Password</InputGroup.Text>
                        <Form.Control
                            type="text"
                            onChange={e=>{password.current=e.target.value}} 
                            placeholder="Reqired"
                            aria-label="password"
                            aria-describedby="in4"
                        />
                    </InputGroup>
                    <InputGroup className="w-100 my-2 mr-2" >
                        <InputGroup.Text id="in5"  style={{width:hw}}  >Re-enter</InputGroup.Text>
                        <Form.Control
                            type="text"
                            onChange={e=>{passwordR.current=e.target.value}}
                            placeholder="Re-enter password"
                            aria-label="re-enter password"
                            aria-describedby="in5"
                        />
                    </InputGroup>
                    <button type="button" onClick={onRenterFrontEnd} className="btn btn-primary d-inline  m_b2" style={{width:'6em'}} >Cancel</button>
                    <button type="button" onClick={onOk} className="btn btn-primary d-inline mx-2" style={{width:'6em'}} >Ok</button>
                </div>
            </div>

            
        </div>
    )
}


