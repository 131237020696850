import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import './app/tagNote.css'

import { Test } from './app/test';
import { Page , PoolPage,NoteBrowser} from './app/elements/page';
import { store } from './app/tagnoteStore';
import { FrontEnd } from './app/FrontEnd';
import { Grid,GridPage } from './app/elements/gridNote';
import { ExpDraft } from './app/exploreDraft';
import { SlateApp } from './app/elements/slateNote';

const container = document.getElementById('root');
const root = createRoot(container);

var leavePageEventHandler;
export function setLeavePageEventHandler(handler){
  leavePageEventHandler = handler;
  //console.log ("page leave handler " + (handler)?'set':'cleared');
} 

window.addEventListener("beforeunload", (ev) => 
{  
  //console.log ("leaving page");
    if (leavePageEventHandler){
      leavePageEventHandler();
    }
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <FrontEnd FrontFrame={NoteBrowser} />
      {/* <Test />  */}
      {/* <ExpDraft /> */}
      {/* <SlateApp /> */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
