// import React, { Component , useEffect,useState } from 'react';
import axios from 'axios';
import { store } from './tagnoteStore';
import $, {  } from 'jquery';
import { getCookie } from './general';
import { showDialog,hideDialog } from './ModalDialog';
import { isDevMachine , ajaxUri } from './webConfig';

//axios.defaults.withCredentials = true;  DEVELOPMENT put this back in - it should send cookies up with the AJAX request

export function ensureCmdArgs(args) {
    
  if (Array.isArray(args)){
    return {
      actions : args
    }
  } else if (args.action) {
      args.actions = [args.action];
      delete args.action;
      return args;
  } else if (!args.actions) {
    return {
      actions : [args]
    }
  } else {
    return args;
  }
  
}


export function sendCommand(args,count=0){

  args = ensureCmdArgs(args);
  const { 
    actions, clearModel , 
    modal , systemFailureIsFatal,failSilently, 
    onCompleted,onSucceeded
  } = args;

  console.log ("Send Cmd...");
  console.log (args);

  const jason_commands = JSON.stringify({
    loggedInUserId : args.loggedInUserId,
    sessionToken : getCookie('HostDiag'), // DEVELOPMENT sessionToken should go up with cookies not like this
    actions : actions
  });

  if (modal) {
    // turn model on
    showDialog({
      title : 'Loading...',
      message:"Please Wait"
    });
    // store.dispatch({ type : 'setMessage', message : 'Please wait' }); 
  }

  function handleResponse (response ) {
    const report = response.data.report;

    console.log ("Cmd Response...");
    console.log (response);

    if (report.ok){

      onFinished('succeeded',{
        modelUpdates : response.data.modelUpdates,
        actionPayloads : response.data.actionPayloads
      });
    
    } else {

      switch ( report.error ) {
        case 'authorization' :
          onFinished('authFailure');
        break;
        default : {
          // connection or server fail so wait and retry
          
          if (count<3 ){
              setTimeout(() => sendCommand(args,count+1),10);
          } else {
            onFinished('systemFailure');
          }
        }break;
      }

    }
  }

  function onFinished( type , successContext={}){
    
    const succeeded = type=='succeeded';
    const systemFailure = type=='systemFailure';

    if (modal) {
      hideDialog();
    }

    if (!succeeded && !failSilently) {
      showDialog( {message:'There was a problem please try later',ok:true});
    } 

    if (succeeded ){

      const {modelUpdates,actionPayloads} = successContext;

      if ( modelUpdates ) {

        var action = {
          type : 'modelUpdate',
          modelUpdates : modelUpdates,
          clearModel : clearModel?clearModel:false
        }
        // if (clearModel && clearModel.clearModel){
        //   action = $.extend (action,clearModel);
        // }
        store.dispatch(action);
      }

      // individual action handlers
      for (var i=0;i<actions.length;i++){
        if (actions[i].onSucceeded){
          actions[i].onSucceeded(actionPayloads[i]);
        }
      }

      if (onSucceeded){
        onSucceeded();
      }
    }

    if (onCompleted){
      onCompleted (
        (systemFailureIsFatal && systemFailure ) ? 'fatalSystemFailure' : type
      );
    }

  }

  axios ( {
    method: 'post',
    url: ajaxUri,//'http://localhost:8888/whicheverphp/command.php',  //MAMP
    data: jason_commands,
    credentials: 'include'
  } ).then (
    response => {
      if (isDevMachine) {
        setTimeout(()=>{handleResponse(response);},10);
      } else {
        handleResponse(response);
      }
    }
    
  );

}
  