
import { useSelector } from "react-redux";
import { useState,useRef,useEffect,useContext } from "react";
import React from "react";
import $, { extend, nodeName } from 'jquery'
import { Button , Container,Row,ButtonGroup} from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import {Stack , DropdownButton } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { filterStack, useMount, useReadableState, useResettableProp } from "../general";
import { sendCommand } from "../Command";
import { setLeavePageEventHandler } from "../..";
import { ToggleButton } from "../general";
import { forceObject,unforceObject, updateStoreModelObject } from "../tagnoteStore";
import { pageContext,browserContext } from "../pageContext";
import { showDialog } from "../ModalDialog";
import { Blank, ContentGrid } from "./gridNote";
import { SlateEditor } from "./slateNote";

export function NewNoteButton({ style , className , onSelect }){

    return  <Dropdown as={ButtonGroup} className={className+" "} style={style}>
        <Button className=" px-2 py-1" onClick={()=>{onSelect('basic')}} >New</Button>

        <Dropdown.Toggle split id="dropdown-split-basic" className="px-2 py-1"/>

        <Dropdown.Menu  style={{  }} >
            <Dropdown.Item onClick={()=>{onSelect('basic') }} >New Basic Note</Dropdown.Item>
            <Dropdown.Item onClick={()=>{onSelect('pool')}}>New Pool</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>

}

export function noteIdComparer(a,b){
    // have to do this to avoid a loading loop
    if (!a != !b ){
        return false;
    }
    if (!!a && !!b){
        return (
            (a.id == b.id) 
        )
    } 
    return true;
}

export function noteComparer(a,b){
    // have to do this to avoid a loading loop
    if (!a != !b ){
        return false;
    }
    if (!a && !b ){
        return true;
    }
    const ja = JSON.stringify(a);
    const jb = JSON.stringify(b);
    const result =  (ja == jb);
    if (!result){
        var i=0;
    }
    return result  ;
 
}


export function getPoolTags(poolNote){
    return $.extend({},poolNote.content.tags);
}

export function getAvailableTags(state,parentNoteId){
    const notes = state.model.notes;

    var poolTags = {};

    if (notes) {
        const poolNote = notes[parentNoteId];
        if (poolNote){
            poolTags = getPoolTags(poolNote);
            // poolTags = $.extend({},poolNote.content.tags);
        }
    }

    return poolTags;
}

export function ParagraphNote({noteId,parentNoteId,bodyOpen,keyId,className,style}){

    const [getBodyOpen,setBodyOpen,changeBodyOpen] = useResettableProp(bodyOpen);
    //const [getContent,setContent] = useReadableState(content);
    // const [getTitle,setTitle,onTitleChanged] = useReadableState("",()=>{changed.current=true});
    const [getTagsVis,setTagsVis,onTagsVis] = useReadableState(false)
    const [view,setView] = useState({tags:true});
    const changed = useRef(false);
    const [isOver, setIsOver] = useState(false);
    const [isFocussed, setIsFocussed] = useState(false);
    const [getTitleEditing,setTitleEditing] = useReadableState(false);
    const titleInput = useRef();
    const title = useRef(null);
    const [dummy,setDummy] = useState();

    // const [getSaveTimer,setSaveTimer] = useReadableState(null);
    
    const [getTitleTimer,setTitleTimer] = useReadableState(null);

    const {gotoPool} = useContext(browserContext);

    const {note,availableTags} = useSelector(state=>{
        var note = null;
        if (state && state.model && state.model.notes){
            note = state.model.notes[noteId];
        }
        var availableTags = getAvailableTags(state,parentNoteId);

        return {
            note,
            availableTags
        };
    },(a,b)=>{
        if (!!a != !!b){
         return false; // one loaded and one not
        } else  if (!a || !b){
            return false; // either of them not loaded
        }
        return JSON.stringify(a)==JSON.stringify(b);
    });


    function clearTitleTimer(){
        const titleTimer = getTitleTimer();
        if (titleTimer){
            clearTimeout(titleTimer);
            setTitleTimer(null);
        }
    }

    function startTitleTimer(){
        clearTitleTimer();
        const titleTimer = setTimeout(() => {
            setTitleTimer(null);
            editTitle();
        }, 500);
        setTitleTimer(titleTimer);
    }
    
    function onFocus(){
        setIsFocussed(true);
        setLeavePageEventHandler(onLeavePage);
    }

    function onLostFocus(e){
        setIsFocussed(false);
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setLeavePageEventHandler(null);
            // ensureContentSaved();
        }
    }

    function onLeavePage(){
        // ensureContentSaved();
    }

    function saveTitle(){
        sendCommand({
            actions:[{
                objectType : 'Note',
                verb : 'update',
                noteId : note.id,
                title: title.current,
            },{
                objectType : 'Note',
                verb : 'fetch',
                noteId : note.id,
            }],
            onCompleted(resultMsg){
                console.log("Title saved : " + note.id + " : " + resultMsg);
            }
    });
    }
    function onTagChanged(tagName,value){
        setTag(tagName,value);
    }

    function setTag(tagName,value){
        
        const tagKey = tagName.toLowerCase();

        const newNote = $.extend(true,{},note);
        
        if(value){
            newNote.tags[tagKey] = tagName;
        } else if (newNote.tags[tagKey]) {
            delete newNote.tags[tagKey];
        }
        
        updateStoreModelObject("notes",note.id,newNote);
        
        sendCommand([{
            objectType : 'Note',
            verb : 'update',
            noteId : note.id,
            tags : JSON.stringify(newNote.tags)
        },{
            objectType : 'Note',
            verb : 'fetch',
            noteId : note.id,
        }]);

    }
    var noteBody = null;

    const menuBtnClassName = "p-1 m-1 ";

    function onTitleBlur(){
        setTitleEditing(false);
        saveTitle();
    }

    function onTitleClick(){

        setBodyOpen(-getBodyOpen())
        
    }

    function editTitle(){
        setTitleEditing(true);
        setTimeout(()=>{titleInput.current.focus();},1);        
    }

    function onDelete(){
        showDialog({
            title : 'Delete Note',
            message : ['Are you sure you want delete this note?','This action can not be undone'],
            cancel : true,
            ok : 'Yes',
            onOk : ()=>{
                sendCommand({
                    actions: [
                        {
                            objectType : 'Note',
                            verb : 'delete',
                            noteId : note.id,
                        },{
                            objectType : 'Pool',
                            verb : 'fetch',
                            parentNoteId : parentNoteId,
                            filter : ''
                        }
                    ],
                    clearModel : 'notes'
                });
            }
        });
        
    }

    function onBrowseTo(){
        gotoPool(note.id);
    }
    
    function onTitleChanged(e){
        title.current = e.target.value;
        setDummy(Math.random());
    }

    const existingPoolTagButtons = [];

    if (note){

        const content = note.content;//JSON.parse(note.content);
        if (title.current === null){
            title.current = (note.title?note.title:"...");
        }
        var nextKeyId = 1;
        
        const tags = note.tags?note.tags:{};

        for(var tagKey in availableTags){
            (function(tagKey){
                var tagName = availableTags[tagKey];
                existingPoolTagButtons.push (
                    React.createElement(ToggleButton,{
                        className:"m-2",
                        size : 'sm',
                        key : nextKeyId++,
                        text : tagName,
                        value : !!tags[tagKey],
                        onChanged : (e)=>{onTagChanged(tagName,e.target.value)}
                    })
                );
            })(tagKey)
        }

        const ContentComponentType = {
            basic : Basic,
            pool : Pool,
            grid : ContentGrid
        }[note.type];

        noteBody = <ContentComponentType
            className={((getBodyOpen()>=0)?"":"d-none") + " w-100 mt-1" }
            style = {{
                // backgroundColor : 'red'
            }}
            value={content} 
            // onChange={onContentChanged}
            noteId = {noteId}
            key = {"note:"+noteId}
            id = "Content"
        />

    }

    style = $.extend({},style,{
        border : (isFocussed || isOver)?'solid 1px #F0F0F0':'solid 1px #FFFFFF',
        width : '100%',
        // backgroundColor : 'green'
    });

    // const isBodyOpen = getBodyOpen();

    return ( <div 
        className={ className + " m-0 p-0 "}
        onBlur = {onLostFocus} onFocus = {onFocus} 
        key={noteId} 
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        style = {style}
        id = "noteParagraph"
    >
        {/* grey header bar */}

        <div
            className="w-100  m-0 p-0"
            style = {{
                backgroundColor : '#F0F0F0',
                position : 'relative',
                cursor : 'pointer'
            }}
            key="headerBar"
            
        >
            {/* top right menu buttons */}
            
            <Stack
                className = { "" }
                direction = "horizontal"
                style = {{
                    position : 'absolute',
                    right : '0.5em',
                    top : '-1em'
                }}
                key="headerMenuStack"
            >
            
                {/* tag visibility button */}
                <ToggleButton 
                    style = {{
                    }}
                    className={menuBtnClassName+" block ms-auto "+((isOver?" ":" invisible") + "")} 
                    value={getTagsVis()} 
                    onChanged={onTagsVis} 
                    text="Tags" 
                ></ToggleButton> 

                <Dropdown
                >
                    <Dropdown.Toggle 
                        className={menuBtnClassName+" block px-2 "+ ((isOver?" ":" invisible"))} 
                                    
                    ></Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={onBrowseTo} >Browse to</Dropdown.Item>
                        <Dropdown.Item onClick={onDelete} >Delete</Dropdown.Item>
                        <Dropdown.Item onClick={editTitle} >Edit title</Dropdown.Item>
                    </Dropdown.Menu>

                    
                </Dropdown>
    
            </Stack>


            {/* title */}
            
            <input 
                className = {(getTitleEditing()?"":"d-none") + " m-0 p-0 w-100 " }
                style = {{  }}
                type="text"
                placeholder="Title"
                ref={(ele) => { titleInput.current = ele; }}
                onBlur = {onTitleBlur}
                onChange = {onTitleChanged}
                value = {title.current}
                key="titleInput"
            />

            <div 
                className = {(getTitleEditing()?"d-none":"") + " w-100"}
                style = {{
                    fontWeight : 'bold',
                    fontSize : '0.9em'

                }}
                onClick = {onTitleClick}
                onMouseDown = {startTitleTimer}
                onMouseUp = {clearTitleTimer}
                onMouseLeave= {clearTitleTimer}
                key="title"
            >
                {title.current}
            </div>

        </div>
        
        <div 
            className= {(getTagsVis()?"":"d-none ")+"  m-0 p-0 "} 
            key="tags"
            style = {{
                display: 'flex',
                // alignItems: 'right',
                justifyContent: 'right',
                flexWrap: 'wrap'
            }}
        >
            {existingPoolTagButtons}
            
        </div>

        {noteBody}

    </div> );

}

export function getNoteContentComponent(noteType){
    return {
        basic : Basic,
        pool : Pool
    }[noteType];

}

export function Basic({className,style,noteId,value,getRef}){

    const [getContent,setContent] = useReadableState(value?$.extend(true,{},value):"{body:''}");
    const [getSaveTimer,setSaveTimer] = useReadableState(null);
    const changed = useRef(false);

    const bc = useContext(browserContext);
    const {gotoPool,newNote,updateNote} = bc?bc:{gotoPool:null,newNote:null};

    function clearSaveTimer(){
        const saveTimer = getSaveTimer();
        if (saveTimer){
            clearTimeout(saveTimer);
            setSaveTimer(null);
        }
    }

    function startSaveTimer(){
        clearSaveTimer();
        const saveTimer = setTimeout(() => {
            setSaveTimer(null);
            ensureContentSaved();
        }, 2000);
        setSaveTimer(saveTimer);
    }

    function onContentChanged (slateContent){
        const content = getContent();
        delete content.body;
        content.slateBody = slateContent;
        setContent(content);
        changed.current= true;
        startSaveTimer();
    }

    useEffect(() => {
        return () => {
            clearSaveTimer();
            ensureContentSaved();
        };
    }, []);

    function ensureContentSaved(){
        if (changed.current){
            var currentContent =  JSON.stringify(getContent());
            sendCommand({
                actions:[{
                    objectType : 'Note',
                    verb : 'update',
                    noteId : noteId,
                    content : currentContent
                },{
                    objectType : 'Note',
                    verb : 'fetch',
                    noteId : noteId,
                }],
                onCompleted(resultMsg){
                    console.log(resultMsg);
                }
        });
            changed.current= false;
            // console.log('Content saved for note : ' +noteId + ' : ' + currentContent)
        }
    }

    function onLostFocus(e){
        // if (!e.currentTarget.contains(e.relatedTarget)) {
            ensureContentSaved();
        // }
    }

    style = $.extend({},style,{
        fontSize : '0.8em',
        lineHeight : 1.3,
        display:'block',
        border: 'none',
        outline: 'none',
        resize: 'none',
    });

    const content = getContent();
    var noteValue = content.slateBody;
    if (content.body){
        const paras = content.body.split("\n");
        noteValue= paras.map(para=>{return {
            type: 'paragraph',
            children: [{ text: para }],
        }})
    }

    return (<SlateEditor
        style={style}
        className={className+ " m-0 p-0"}
        value = {noteValue}
        onChange = {onContentChanged}
        onBlur = {onLostFocus} 
        ref = {getRef}
    />)
}
    
export function Pool({noteId,className,style}){
    
    const {gotoPool} = useContext(browserContext);

    style = $.extend({},style,{
        display:'block'
    });

    function onOpen(){
        gotoPool(noteId);
    }

    return (<div
        className={className + " mx-auto"}
        style = {style}
    >
        <Button variant="outline-primary" size="sm" onClick={onOpen}>Open Pool</Button>
    </div>);
}

export function ParagraphList({
    parentNoteId,
    filter,
    style,
    className,
    bodiesOpen
}){

    // console.log('rendering ParagraphList for '+parentNoteId);

    const allNotes = useSelector(state=>{
        const notes = state.model.notes;

        var allNotes = [];

        if (notes) {
            allNotes = filterStack (notes,"parent",parentNoteId);
            
        }

        return allNotes
    },(a,b)=> (a?a.map(n=>n.id.toString()).join(","):'') ==  (b?b.map(n=>n.id.toString()).join(","):''));


    var filteredNotes = allNotes;

    if (filter){

        var ors = null;
        var ands = null;
        var nots = null;

        for (var tagKey in filter){
            
            var f = filter[tagKey];
            if (f) {
                if (f.or){
                    ors=ors?ors:{};
                    ors[tagKey]=true;
                }
                if (f.and){
                    ands=ands?ands:{};
                    ands[tagKey]=true;
                }
                if (f.not){
                    nots=nots?nots:{};
                    nots[tagKey]=true;
                }
            }
        }

        if (ors || ands || nots) {
            filteredNotes = $.grep(allNotes,note=>{
                var passedOr = true;
                if (ors){
                    passedOr = false;
                    for (var tagKey in ors){
                        if (note.tags[tagKey]){
                            passedOr = true; 
                            break;
                        }
                    } 
                }
                if (!passedOr){
                    return false;
                }
                
                if (ands){
                    for (var tagKey in ands){
                        if (!note.tags[tagKey]){
                            return false;
                        }
                    }
                }

                if (nots){
                    for (var tagKey in nots){
                        if (note.tags[tagKey]){
                            return false;
                        }
                    }
                }
                return true;                   
            });
        }
    } 

    var nextKeyId = 1;

    const notes = filteredNotes.map( note => { 
        return React.createElement(ParagraphNote,{
            bodyOpen:bodiesOpen,
            noteId : note.id,
            parentNoteId,
            keyId:note.id,
            key:note.id,
            className : "mb-2"
        }) 
    } );

    style = $.extend({},style,{});

    return ( <div
        id = 'paragraphList'
        className = {className+" m-0 p-0"}
        style = {style}
    >
        {notes}
        
    </div> );
}